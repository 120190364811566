import React, { forwardRef, useState } from "react";
import ReactPlayer from "react-player";
// import AuthService from "./auth/AuthService";
// import QuestionDropDown from "./common/QuestionDropDown";
import VideoDropDown from "./common/VideoDropDown";
import QuestionSearch from "./common/QuestionSearch";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import config from "../config";
import VideoTimeLine from "./common/VideoTimeLine";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { sasLink } from "./common/lib/sync/videoSyncLib";
import {
  listAssessments,
  listVideosOnly,
  // pushNewData,
  // removeVideo,
  // updateFileSize,
} from "./common/lib/sync/videoSyncLib";

import { getQuestions } from "./common/lib/QuestionCard";

// const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 15,
  },
  video: {
    margin: 15,
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  select: {
    backgroundColor: "#bcbcbc",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideosQuestions() {
  const [myVideo, setMyVideo] = useState(null);
  const [selection, setSelection] = useState([]);

  const [videosList, setVideosList] = useState([]);

  const [filename, setFilename] = useState("");
  const [videoId, setVideoId] = useState(0);
  const [playing, setPlaying] = useState(true);
  const [comp, setComp] = useState(null);
  const [questionSeek, setQuestionSeek] = useState(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [assessments, setAssessments] = useState([]);
  const [assessment, setAssessment] = useState([0, 0, 0, 0]);
  // const [seeks, setSeeks] = useState([]);
  const handleVideoRef = async (component) => {
    if (component) {
      setComp(component);
    }
  };

  const itemSaved = (items, resume) => {
    getQuestions(selection, videoId)
      .then((seekQuestions) => {
        setQuestionSeek(seekQuestions);
      })
      .catch((err) => {
        console.log("error", err);
      });
    if (resume) {
      setPlaying(true);
    }
  };

  const timeSelected = (time) => {
    comp.seekTo(time);
  };

  const handleClose = () => {
    console.log("playedSeconds", playedSeconds);
    comp.seekTo(playedSeconds + 1);
    setPlaying(true);
    // setTimeout(() => {
    //   setPlaying(true);
    // }, 1000);
  };

  const classes = useStyles();

  const handleChange = (video) => {
    // console.log('###video', video)
    setVideoId(0);
    setFilename("");
    setQuestionSeek([]);
    setMyVideo("");
    getQuestions(selection, video.id)
      .then((seekQuestions) => {
        setVideoId(video.id);
        setFilename(video.video_link);
        setQuestionSeek(seekQuestions);
        console.log(
          "parseInt(video.downloaded_size) < parseInt(video.total_size)",
          parseInt(video.downloaded_size),
          parseInt(video.total_size)
        );
        if (parseInt(video.downloaded_size) < parseInt(video.total_size)) {
          sasLink(video.id)
            .then((data) => {
              console.log("if part data.link", data.link);
              setMyVideo(`${data.link}`);
            })
            .catch((e) => {
              console.log("e", e);
            });
          // setMyVideo(`${config.videoEndPointAzure}${video.video_link}`);
        } else {
          console.log("else part video.video_id", video.id);
          setMyVideo(`${config.videoEndPoint}${video.id}`);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  React.useEffect(() => {
    if (selection[0] && selection[1] && selection[2] && selection[3]) {
      // Auth.post("crud", {
      //   school_id: 1,
      //   action: "select",
      //   table: "videos",
      //   order: "video_order",
      //   keys_object: JSON.stringify({
      //     status: 1,
      //     grade_id: selection[0],
      //     subject_id: selection[1],
      //     level_id: selection[2],
      //     topic_id: selection[3],
      //   }),
      // })
      //   .then((res) => {
      //     if (res.status) {
      //       setVideosList(res.data);
      //     }
      //     console.log("res###", res);
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   });

      listVideosOnly(selection)
        .then((videoList) => {
          setVideosList(videoList);
        })
        .catch((e) => {
          console.log("error", e);
        });

      listAssessments(selection[2])
        .then((items) => {
          setAssessments(items);
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  }, [selection]);

  return (
    <>
      <Grid item xs={12}>
        <h1>Manage Video Question</h1>
      </Grid>
      {/* <QuestionDropDown selection={setSelection} /> */}
      <VideoDropDown selection={setSelection} />
      <Grid item xs={3}>
        {assessments.length ? (
          <Autocomplete
            id="assessments-2"
            options={assessments}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              // console.log("####", [
              //   selection[0],
              //   selection[1],
              //   selection[2],
              //   newValue.id,
              // ]);
              setAssessment([
                selection[0],
                selection[1],
                selection[2],
                newValue ? newValue.id : 0,
              ]);
              // addAssessment(newValue ? newValue : "");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assessments List"
                variant="outlined"
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      {selection[0] && selection[1] && selection[2] && selection[3] ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <ReactPlayer
                ref={(component) => handleVideoRef(component)}
                playing={playing}
                className={classes.video}
                url={myVideo}
                controls={true}
                onProgress={(e) => {
                  if (!playing) return;
                  const sec = parseInt(e.playedSeconds);
                  console.log("sec", sec, playing);
                  setPlayedSeconds(sec);
                  // if (seeks.includes(sec)) {
                  //   setPlaying(false);
                  // } else {
                  //   console.log(sec, seeks);
                  // }
                }}
                onDuration={(e) => {
                  // saveVideo(e);
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  setPlaying(false);
                }}
              >
                Add Question at {playedSeconds} seconds
              </Button>
            </Grid>

            <Grid item xs={3}>
              {videosList.map((item, index) => (
                <ListItem
                  className={videoId === item.id ? classes.select : ""}
                  button
                  key={index}
                >
                  <ListItemText
                    onClick={() => {
                      handleChange(item);
                    }}
                    primary={`${
                      item.status === "1" ? item.video_link.split("/")[2] : ""
                    }`}
                  />
                </ListItem>
              ))}
            </Grid>
          </Grid>
          {questionSeek ? (
            <Grid item xs={12}>
              <VideoTimeLine
                timeSelected={timeSelected}
                questionSeek={questionSeek}
              />
            </Grid>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <Dialog
        fullScreen
        open={!playing}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Manage Questions For video {filename} at {playedSeconds} seconds
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              Remove
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid item xs={12}>
          <QuestionSearch
            videoId={videoId}
            playedSeconds={playedSeconds}
            selection={assessment}
            itemSaved={itemSaved}
            // questionSeek={questionSeek}
            popupHide={true}
          />
        </Grid>
      </Dialog>
    </>
  );
}
