import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import Path from "path";
import uploadFileToBlob from "./uploadToBlob";
import config from "../../../config";

import { removeFileByKey } from "./sync/videoSyncLib";

const useStyles = makeStyles((theme) => ({
  button: {
    // position: "relative",
  },
  cursor: { cursor: "pointer" },
  image: {
    height: 80,
  },
}));

const ImageUpload = (props) => {
  const classes = useStyles();
  const [blobItem, setBlobItem] = useState("");

  React.useEffect(() => {
    if (props.restImages !== null) setBlobItem("");
  }, [props.restImages]);

  React.useEffect(() => {
    console.log("props.editImage", props.editImage);
    if (props.editImage && props.editImage !== "null")
      setBlobItem(props.editImage);
  }, [props.editImage]);

  const [uploading, setUploading] = useState(false);
  // const [inputKey, setInputKey] = useState(Math.random().toString(12));
  // const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  
  const handleUploadphp = async (file,path) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    try {
      const response = await fetch(`${config.domain}uploadfile`, {
        method: 'POST',
        body: formData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
      const data = await response.json();
      console.log(data);
      return data.key;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };


  const onFileUpload = async (event) => {
    setUploading(true);
    // setFile(event.target.files[0]);
    const blobsInContainer = await handleUploadphp(event.target.files[0],props.blobContainer.join("-"));
    console.log("blobsInContainer  :", blobsInContainer)
    // const blobsInContainer = await uploadFileToBlob(
    //   event.target.files[0],
    //   "uploads",
    //   props.blobContainer.join("-")
    // );
    setBlobItem(blobsInContainer);
    console.log("{blobItem: ", blobItem, blobsInContainer)
    setUploading(false);
    props.blobItem(blobsInContainer, props.name);
    // setInputKey(Math.random().toString(36));
  };

  const DisplayImage = () => (
    <>
      <HighlightOffIcon
        className={classes.cursor}
        onClick={() => {
          removeFileByKey(blobItem).then((data) => {
            console.log("data", data);
          });
          console.log("dd", blobItem);
          setBlobItem("");
          props.blobItem("", props.name);
        }}
      />
      <img
        className={classes.image}
        title={blobItem}
        // title={Path.basename(blobItem)} //@TODO fix path
        src={config.imageCDN + blobItem}
        alt={blobItem}
      />
    </>
  );
  const DisplayForm = () => (
    <Button className={classes.button} variant="contained" component="label">
      {props.label}
      <input
        type="file"
        accept="image/*"
        onChange={onFileUpload}
        style={{ display: "none" }}
      />
    </Button>
  );

  return (
    <>
      {/* // <Grid container> */}
      {/* <Grid item xs={12}> */}
      {!uploading && !blobItem && DisplayForm()}
      {uploading && <div>Uploading</div>}
      {blobItem && DisplayImage()}
      {/* // </Grid> */}
      {/* <hr /> */}
      {/* <Grid item xs={3}>
        {!storageConfigured && <div>Storage is not configured.</div>}
      </Grid> */}
    </>
  );
};

export default ImageUpload;
