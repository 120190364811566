// import { stat } from "fs";
import { useState } from "react";

const useForm = (defaultValues) => {
  const [state, setState] = useState(defaultValues);

  // const handleChange = (e) => {
  //   e.persist();
  //   let name = e.target.name
  //   let value = e.target.value
  //   let nameArr = name.split("_")
  //   // console.log('nameArr.length', nameArr.length)
  //   if(nameArr.length > 1) {
  //     name = nameArr[0]
  //   }
  //   setState((state) => ({ ...state, [nameArr[0][nameArr[1]]]: value }));
  // };
  const handleChange = (el) => {
    if (!el.custom) {
      el.persist();
    }
    let name = el.target.name;
    let value = el.target.value;
    let nameArr = name.split("--");
    setState((state) => {
      let statusCopy = Object.assign({}, state);
      if (nameArr.length > 1) {
        statusCopy[nameArr[0]][nameArr[1]] = value;
      } else {
        statusCopy[name] = value;
      }
      return statusCopy;
    });
  };
  const reset = (obj) => {
    // console.log('obj' , obj)
    setState(obj)
  };
  return [state, handleChange, reset];
};

export default useForm;
