import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
import SyncCard from "./sync/SyncCard";
// import SyncIcon from "@material-ui/icons/Sync";

import List from "@material-ui/core/List";

import { listVideosOnly } from "./sync/videoSyncLib";

const VideoSync = (props) => {
  const [videosList, setVideosList] = useState([]);

  useEffect(() => {
    let timer = null;
    console.log(props.blobContainer, "props.blobContainer");
    listVideosOnly(props.blobContainer)
      .then((videoList) => {
        console.log("set");
        setVideosList(videoList);
        // if (videosList.length) {
        timer = window.setInterval(() => {
          listVideosOnly(props.blobContainer)
            .then((videoList) => {
              console.log("set");
              setVideosList(videoList);
            })
            .catch((e) => {
              console.log("error", e);
            });
        }, 10000);
        console.log("set Interval", timer);
        // }
      })
      .catch((e) => {
        console.log("error", e);
      });
    return () => {
      console.log("clear Interval", timer);
      window.clearInterval(timer);
    };
  }, [props.blobContainer]);

  return (
    <>
      {/* <Grid item xs={12}>
        <h1>SYNCING</h1>
        <Button
          onClick={() => {
            syncAll(props.blobContainer);
          }}
          variant="contained"
          color="primary"
        >
          Sync All <SyncIcon />
        </Button>
      </Grid> */}

      <Grid item xs={12}>
        <List>
          {videosList.map((card, i) => (
            <SyncCard key={i} videoItem={card} />
          ))}
        </List>
      </Grid>
    </>
  );
};

export default VideoSync;
