import React, { Component } from "react";

import AuthService from "../auth/AuthService";
import AddStudent from "./AddStudent";
import EditStudent from "./EditStudent";

import Message from "../common/Message";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Tooltip from "@material-ui/core/Tooltip";
// import DeleteIcon from "@material-ui/icons/Delete";

class ListStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      loadMessage: "",
      type: "success",
    };

    this.msgInterval = null;
    this.Auth = new AuthService();
    this.fetchData = this.fetchData.bind(this);
    this.studentAdded = this.studentAdded.bind(this);
  }

  componentDidMount() {
    if (!this.Auth.loggedIn()) this.props.history.replace("/");
    this.setState({ loadMessage: "Loading Students", type: "warning" });
    this.fetchData("Students Loaded.");
  }

  deleteUser(id) {
    console.log("delete clicked");
    this.Auth.post("crud", {
      action: "update",
      table: "student",
      id_key: "student_id",
      id_value: id,
      is_deleted: "1",
    })
      // this.Auth.fetch("api/user/delete_user", {
      //   method: "POST",
      //   body: JSON.stringify({
      //     id: id,
      //   }),
      // })
      .then((res) => {
        this.fetchData("Students Loaded.");

        console.log(res);
        if (res.success === false) {
          this.setState({ isloading: false, errors: { form: res.message } });
        }
      })
      .catch((err) => {
        this.setState({ isloading: false, errors: { form: err } });
      });
  }

  updateUser(id) {
    console.log("update clicked");
  }

  fetchData(msg) {
    //   this.Auth.fetch("/api/user/list")
    //     .then(res => {
    //       this.setState({
    //         users: res.users,
    //         loadMessage: "Students Loaded",
    //         type: "success",
    //       });
    //     })
    //     .catch(err => {
    //       this.setState({
    //         loadMessage: "some error occurred please try again",
    //         type: "error",
    //       });
    //       console.log(err);
    //     });

    console.log("loading data in main..");
    this.Auth.post("crud", {
      action: "select",
      table: "tv_student",
      keys_object: JSON.stringify({
        is_deleted: "0",
      }),
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          let data = res.data;
          // data = data.filter((student) => student.is_deleted === 1);
          // console.log("mydata", data[0].is_deleted);
          this.setState({
            students: data,
            loadMessage: "Students Loaded",
            type: "success",
          });
        }
        console.log("res", res);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  studentAdded() {
    this.fetchData("loading new students");
  }

  render() {
    // const { classes } = this.props;
    return (
      <div style={{ padding: "20px" }}>
        <AddStudent
          propAddLink={this.propAddLink}
          plus={true}
          studentAdded={this.studentAdded}
        />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Grade</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Section</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Roll No.</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Guradian Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Guardian CNIC
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>DOB</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>District</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>City</TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold" }}>Model</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>
                  Mother Tongue
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Never been to School
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Child Labor
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="right">
                  Actions{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.students.map(
                (row, index) => (
                  // row.status === "0" ? (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.student_name}
                    </TableCell>
                    <TableCell>{row.grade_name}</TableCell>
                    <TableCell>{row.section_name}</TableCell>
                    <TableCell>{row.student_roll_no}</TableCell>
                    <TableCell>{row.guardian_name}</TableCell>
                    <TableCell>{row.guardian_cnic}</TableCell>
                    <TableCell>{row.student_dob}</TableCell>
                    <TableCell>{row.district}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    {/* <TableCell>{row.city}</TableCell> */}
                    {/* <TableCell>{row.model}</TableCell> */}
                    <TableCell>{row.mother_tongue}</TableCell>
                    <TableCell>
                      {row.never_been_to_school === "1" ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row.child_labor}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell align="right">
                      {/* <Tooltip title="delete">
                        <DeleteIcon
                          style={{ cursor: "pointer", float: "right" }}
                          onClick={(event) => {
                            if (
                              window.confirm(
                                "Are you sure you wish to delete this user?"
                              )
                            )
                              this.deleteUser(row.student_id);
                          }}
                        />
                      </Tooltip> */}
                      <EditStudent
                        propAddLink={this.propAddLink}
                        plus={true}
                        student={row}
                      />
                    </TableCell>
                  </TableRow>
                )
                // ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Message msg={this.state.loadMessage} type={this.state.type} />
      </div>
    );
  }
}

export default ListStudents;
