import React, { useEffect, useState, useContext, useCallback } from "react";
import ReactPlayer from "react-player";
import AuthService from "./auth/AuthService";
import MediaDropDown from "./common/MediaDropDown";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sasLink } from "./common/lib/sync/videoSyncLib";
import config from "../config";
import ThingsContext from "./Layout/thingsContext";
// import Promotion from "./promotion/Promotion";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 15,
  },
  video: {
    margin: 15,
  },
  nextButton: {
    padding: theme.spacing(2),
    textAlign: "center",
    top: "15px",
    // color: theme.palette.text.secondary,
  },
}));

export default function Media({ attendance }) {
  const ref = React.useRef(null);
  const [myVideo, setMyVideo] = useState("");
  const [assessment, setAssessment] = useState("");
  const [seek, setSeek] = useState(0);
  const [attendanceIds, setAttendanceIds] = useState([]);
  const [selection, setSelection] = useState(["", "", ""]);
  const [videoId, setVideoId] = useState(0);
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [things, setThings] = useContext(ThingsContext);

  const classes = useStyles();

  useEffect(() => {
    if (!Auth.loggedIn()) return window.location.replace("/");
  }, []);

  const playVideo = (name) => {
    setMyVideo(name);
  };
  const saveVideo = (duration) => {
    Auth.post("saveVideoLog", {
      video_id: videoId,
      attendance_ids: attendanceIds.join(","),
      duration: duration,
      log_device: 1,
    })
      .then((res) => {
        console.log("saveVideoLog", res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateVideoLogs = (seek, end) => {
    console.log("attendanceIds", attendanceIds);
    if (!attendanceIds.length) {
      return;
    }
    let obj = {
      attendance_ids: attendanceIds.join(","),
      seek: parseInt(seek),
      video_id: videoId,
    };
    if (end) {
      obj["done"] = 1;
    }
    console.log("obj", obj);
    Auth.post("updateVideoLog", obj)
      .then((res) => {
        // console.log("saveVideoLog", res);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const playVideoFunc = useCallback((item) => {
    setAssessment("");
    setVideoId(item.video_id);
    if (parseInt(item.downloaded_size) < parseInt(item.total_size)) {
      sasLink(item.video_id)
        .then((data) => {
          playVideo(`${data.link}`);
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
      playVideo(`${config.videoEndPoint}${item.video_id}`);
    }

    setSeek(parseInt(item.seek ? item.seek : 0));
  }, []);
  const process = useCallback(() => {
    Auth.post("getRunningVideo", {
      grade_id: selection[0],
      section_id: selection[1],
      subject_id: selection[2],
      first: true,
      start_date: formatDate(new Date()),
    })
      .then((res) => {
        if (res.status) {
          // console.log("res.data", res.data);
          let item = res.data[0];
          if (item.status === "2") {
            setAssessment(item.assessment_name);
            setVideoId(0);
            playVideo("");
            setSeek(0);
          } else {
            playVideoFunc(item);
          }
        } else {
          setAssessment("");
          setVideoId(0);
          playVideo("");
          setSeek(0);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [selection, playVideoFunc]);

  useEffect(() => {
    if (selection[0] && selection[1]) {
      Auth.post("attendanceByDate", {
        // school_id: 1,
        grade_id: selection[0],
        section_id: selection[1],
        start_date: formatDate(new Date()), // @TODO Attendance
      })
        .then((res) => {
          let items = [];
          let items2 = [];
          if (res.status) {
            res.data.forEach((element) => {
              items.push({
                student_name: element.student_name,
                present: element.present,
              });
              if (element.present === "1") items2.push(element.attendence_id);
            });
          }
          attendance(items);
          setAttendanceIds(items2);
          console.log("items", items, items2);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [selection, attendance]);

  useEffect(() => {
    if (selection[0] && selection[1] && selection[2]) {
      setThings(selection);
      process();
    }
  }, [process, selection, setThings]);

  useEffect(() => {
    ref.current && ref.current.seekTo(seek, "seconds");
  }, [seek]);

  useEffect(() => {
    console.log("things...", things);
    if (things.length === 4 && things[3] !== null) {
      let item = things[3];
      playVideoFunc(item);
    }
  }, [things, playVideoFunc]);

  return (
    <>
      <Grid item xs={12}>
        <h1>Media Player ({formatDate(new Date())})</h1>
      </Grid>
      <MediaDropDown selection={setSelection} />

      {selection[0] && selection[1] && selection[2] ? (
        <>
          <Grid item xs={6} sm={4} lg={3}>
            <Button
              onClick={() => {
                process();
              }}
              variant="contained"
              color="primary"
              className={classes.nextButton}
            >
              Next
            </Button>
          </Grid>

          {attendanceIds.length ? (
            <Grid item xs={12}>
              {assessment && `Assessment Time: "${assessment}"`}
              {/* {!myVideo && "No video Available for this session."} */}
              <ReactPlayer
                ref={ref}
                playing={false}
                className={classes.video}
                url={myVideo}
                controls={true}
                onProgress={(e) => {
                  updateVideoLogs(e.playedSeconds, false);
                }}
                onDuration={(e) => {
                  saveVideo(e);
                }}
                onEnded={(e) => {
                  updateVideoLogs(0, true);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <p>No Attendance marked.</p>
            </Grid>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
