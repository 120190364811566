import AuthService from "../../../auth/AuthService";
const Auth = new AuthService();

export const listAssessments = (id_value) => {
  // console.log("id_value", id_value);

  return Auth.post("crud", {
    id_key: "level_id",
    id_value: id_value,
    action: "select",
    table: "assessments",
  })
    .then((res) => {
      let items = [];
      res.data.forEach((element) => {
        items.push({
          id: element.assessment_id,
          name: element.assessment_name,
        });
      });
      return Promise.resolve(items);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const listVideos = (blobContainer) => {
  return Auth.post("crud", {
    // school_id: 1,
    action: "select",
    table: "videos",
    order: "video_order",
    keys_object: JSON.stringify({
      grade_id: blobContainer[0],
      subject_id: blobContainer[1],
      level_id: blobContainer[2],
      topic_id: blobContainer[3],
    }),
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const syncAll = (blobContainer) => {
  return Auth.post("download-video", {
    grade_id: blobContainer[0],
    subject_id: blobContainer[1],
    level_id: blobContainer[2],
    topic_id: blobContainer[3],
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const sasLink = (id) => {
  return Auth.post("downloadLinkWithSAS", {
    id,
  })
    .then((res) => {
      if (res.status) {
        console.log(res)
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const listVideosOnly = (blobContainer) => {
  return Auth.post("crud", {
    // school_id: 1,
    action: "select",
    table: "videos",
    order: "video_order",
    keys_object: JSON.stringify({
      grade_id: blobContainer[0],
      subject_id: blobContainer[1],
      level_id: blobContainer[2],
      topic_id: blobContainer[3],
      status: "1",
    }),
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const listSyncVideos = (check) => {
  return Auth.post("sync-all", {
    check,
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const pushNewData = (id) => {
  return Auth.post("crud", {
    action: "select",
    table: "videos",
    id_key: "id",
    id_value: id,
  })
    .then((res) => {
      return Promise.resolve(res.data[0]);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const updateFileSize = (name, loadedBytes) => {
  return Auth.post("crud", {
    action: "update",
    table: "videos",
    id_key: "video_link",
    id_value: name,
    uploaded_size: loadedBytes,
  })
    .then((res) => {
      return Promise.resolve(res.data[0]);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const removeVideo = (id) => {
  // console.log('removeVideoremoveVideoid', id)
  return Auth.post("delete-file", {
    id: id,
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const softDelete = (id, deleted) => {
  // console.log('ididididid', id, !parseInt(deleted))
  return Auth.post("soft-delete", {
    id: id,
    deleted: (deleted)
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const removeFileByKey = (blobItem) => {
  // console.log('removeVideoremoveVideoid', id)
  return Auth.post("delete-file-by-key", {
    blobItem,
  })
    .then((res) => {
      console.log("res", res);
      if (res.message) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
