import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AuthService from './AuthService';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import config from '../../config';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://takmil.org/">
        MSN72
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Auth = new AuthService();

export default function SignIn(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    console.log('load signin...');
    if (Auth.loggedIn())
      return props.history.push(
        config.app === 'Studio' ? '/media' : '/question'
      );

    let object = {};

    if (config.app === 'Studio') {
      object.online = config.pi === true ? 0 : 1;
    }

    Auth.post('getSchools', object)
      .then((res) => {
        console.log('res.data', res);
        if (res.status) {
          setSchools(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.history]);

  function handleSubmit(event) {
    event.preventDefault();
    Auth.login(username, password)
      .then((res) => {
        // props.history.replace("/media");
        window.location.reload();
        // console.log('res', res)
      })
      .catch((err) => {
        enqueueSnackbar(`Login Fail, Pleas try again!`, {
          variant: 'error',
        });
        console.log('err', err);
      });

    // console.log( 'Email:', email.value, 'Password: ', password.value);
    // if (this.isValid())
    // Auth.fetch("loginStudents", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    //   body: new URLSearchParams({
    //     school_id: 1,
    //     role: "Teacher",
    //     password: password,
    //     username: username,
    //   }),
    // })
    //   .then((res) => {
    //     console.log("res", res);
    //     if (res.status) {
    //       localStorage.setItem("user_id", res.data.user_id);
    //       // props.history.replace("/media");
    //       window.location.reload();
    //     }
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  }

  // const handleChangeSchool = (event) => {
  //   console.log("inside hanelschoolchange, ", event.target.value);
  // let value = event.target.value;
  // console.log(value);
  // console.log(schools);
  // let school = schools.filter((obj) => {
  //   return obj.name == value;
  // });

  // console.log(school);
  // setSchool(school);
  // getTeachers(school);
  // };

  const getTeachers = (school_id) => {
    setTeachers([]);

    Auth.post('studentUsers', {
      school_id: school_id,
      app: config.app,
    })
      .then((res) => {
        if (res.status) {
          let items = [];
          let teachers_list = res.data.teachers_list;
          teachers_list.forEach((element) => {
            items.push({
              title: element.email,
              name: `${element.user_name} (${element.email})`,
            });
          });
          console.log('data', res);

          setTeachers(items);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Alert
            onClose={() => {
              setError(null);
            }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          {schools.length ? (
            <Autocomplete
              id="schools-list"
              options={schools}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log(newValue.id);
                setSchool(newValue ? newValue.id : '');
                getTeachers(newValue.id);
              }}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={school}
                  label="Schools List"
                  variant="outlined"
                />
              )}
            />
          ) : (
            ''
          )}
          {teachers.length ? (
            <Autocomplete
              id="combo-box-demo"
              options={teachers}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setUsername(newValue ? newValue.title : '');
              }}
              style={{ width: 300, marginTop: '10px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Teachers List"
                  variant="outlined"
                />
              )}
            />
          ) : (
            ''
          )}

          <TextField
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
