import React, { Component } from "react";
import districts from "../../constants/districts";

import Button from "@material-ui/core/Button";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthService from "../auth/AuthService";
import Message from "../common/Message";
import cities from "../../constants/cities";

export default class AddStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: "",
      grade_name: "",
      section_name: "",
      grade_id: "",
      section_id: "",
      student_name: "",
      student_relation: "",
      student_gender: "",
      student_dob: "",
      // student_roll_no: "",
      guardian_name: "",
      guardian_cnic: "",
      student_image: "",
      status: "",
      district: "",
      disability: "",
      relegion: "",
      tehsil: "",
      village: "",
      council: "",
      province: "",
      city: "",
      model: "",
      mother_tongue: "",
      never_been_to_school: "",
      type_of_prior_schooling: "",
      child_labor: "",
      dropout_reason: "",

      password: "",
      enrollment_date: "",
      open: false,

      meta_classes: [],
      meta_sections: [],

      errorMessage: "",
      grade_nameErrorMessage: "",
      section_nameErrorMessage: "",
      student_nameErrorMessage: "",
      student_genderErrorMessage: "",
      student_dobErrorMessage: "",
      // student_roll_noErrorMessage: "",
      guardian_nameErrorMessage: "",
      guardian_cnicErrorMessage: "",
      student_imageErrorMessage: "",
      passwordErrorMessage: "",
      // enrollment_dateErrorMessage: "",
      serverError: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }
  // const[open, setOpen] = React.useState (false);

  handleClickOpen() {
    this.resetForm();
    this.Auth.post("classes")
      .then((data) => {
        this.setState({
          meta_classes: data.data,
        });
        console.log(data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    this.setState({ open: true });
  }

  resetForm() {
    console.log("inside reset");
    this.setState({
      open: false,
      errorMessage: "",
      grade_nameErrorMessage: "",
      section_nameErrorMessage: "",
      student_nameErrorMessage: "",
      // student_genderErrorMessage: "",
      student_dobErrorMessage: "",
      // student_roll_noErrorMessage: "",
      guardian_nameErrorMessage: "",
      guardian_cnicErrorMessage: "",
      student_imageErrorMessage: "",
      passwordErrorMessage: "",
      // enrollment_dateErrorMessage: "",
      serverError: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState(
      {
        grade_nameErrorMessage:
          this.state.grade_name === "" ? "Grade is required " : "",
        section_nameErrorMessage:
          this.state.section_name === "" ? "Section is required " : "",
        student_nameErrorMessage:
          this.state.student_name === "" ? "Name is required " : "",
        // student_genderErrorMessage:
        //   this.state.student_gender === "" ? "Gender is required " : "",
        student_dobErrorMessage:
          this.state.student_dob === "" ? "DOB is required " : "",
        // student_roll_noErrorMessage:
        //   this.state.student_roll_no === "" ? "Roll No. is required " : "",
        guardian_nameErrorMessage:
          this.state.guardian_name === "" ? "Guradian name is required " : "",
        guardian_cnicErrorMessage:
          this.state.guardian_cnic === "" ? "Guardian CNIC is required " : "",
        student_imageErrorMessage:
          this.state.student_image === "" ? "Student Image is required " : "",
        passwordErrorMessage:
          this.state.password === "" ? "Password is required " : "",
        // enrollment_dateErrorMessage:
        //   this.state.enrollment_date === "" ? "Enrollment is required " : "",

        // classErrorMessage: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        // this.state.email
        // )
        // ? ""
        // : "Invalid email address",
      },
      () => {
        console.log("sssssssssss", this.state);
        if (
          this.state.grade_nameErrorMessage.length > 0 ||
          this.state.section_nameErrorMessage.length > 0 ||
          this.state.student_nameErrorMessage.length > 0 ||
          // this.state.student_genderErrorMessage.length > 0 ||
          this.state.student_dobErrorMessage.length > 0 ||
          // this.state.student_roll_noErrorMessage.length > 0 ||
          this.state.guardian_nameErrorMessage.length > 0 ||
          this.state.guardian_cnicErrorMessage.length > 0 ||
          // this.state.student_imageErrorMessage.length > 0 ||
          this.state.passwordErrorMessage.length > 0
          // this.state.enrollment_dateErrorMessage.length > 0
        ) {
          this.setState({
            serverError: "Invalid data provided",
          });
          console.log("Invalid data provided");
        } else {
          const data = {
            grade_id: this.state.grade_name,
            section_id: this.state.section_name,
            student_name: this.state.student_name,
            student_gender: this.state.student_gender,
            student_dob: this.state.student_dob,
            // student_roll_no: this.state.student_roll_no,
            guardian_name: this.state.guardian_name,
            guardian_cnic: this.state.guardian_cnic,
            student_image: this.state.student_image,
            student_relation: this.state.student_relation,
            tehsil: this.state.tehsil,
            village: this.state.village,
            council: this.state.council,
            disability: this.state.disability,
            relegion: this.state.relegion,
            // guardian: this.state.guardian,
            password: this.state.password,
            enrollment_date: "2021-01-01",
            status: this.state.status,
            district: this.state.district,
            city: this.state.city,
            model: this.state.model,
            mother_tongue: this.state.mother_tongue,
            never_been_to_school: this.state.never_been_to_school,
            type_of_prior_schooling: this.state.type_of_prior_schooling,
            dropout_reason: this.state.dropout_reason,
            child_labor: this.state.child_labor,
          };
          console.log(data);
          this.Auth.post("signupStudents", data)
            .then((res) => {
              console.log(res.data.error);
              if (res.data.error) {
                this.setState({ serverError: res.data.error });
              } else {
                this.setState({
                  grade_name: "",
                  section_name: "",
                  grade_id: "",
                  section_id: "",
                  student_name: "",
                  student_gender: "",
                  student_dob: "",
                  // student_roll_no: "",
                  guardian_name: "",
                  guardian_cnic: "",
                  student_image: "",
                  password: "",
                  enrollment_date: "",
                  status: "",
                  district: "",
                  city: "",
                  model: "",
                  mother_tongue: "",
                  never_been_to_school: "",
                  type_of_prior_schooling: "",
                  dropout_reason: "",
                  child_labor: "",
                  open: false,
                });
                // window.location.reload();
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.setState({
                errorMessage: "some error occurred please try again",
              });
            })
            .finally(() => {
              console.log("Loading data from add-student");
              this.props.studentAdded();
            });
        }
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (name === "grade_name") {
      console.log("inside grade name");

      this.Auth.post("sections", {
        grade_id: value,
      })
        .then((data) => {
          this.setState({
            section_id: 0,
            section_name: "",
            meta_sections: data.data,
          });
          console.log(data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }

  render() {
    const { plus } = this.props;
    return (
      <div>
        {plus ? (
          <Tooltip title="Add Student">
            <AddCircleIcon
              style={{
                cursor: "pointer",
                fontSize: 30,
                float: "right",
                color: "#FF6162",
              }}
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            />
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Open form dialog
          </Button>
        )}
        <br style={{ clear: "both" }} />

        <form>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Create User</DialogTitle>
            <Message msg={this.state.serverError} type="error" />
            <DialogContent>
              <TextField
                id="grade_name"
                name="grade_name"
                label="Grade"
                select
                fullWidth
                value={this.state.grade_name}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                helperText={this.state.grade_nameErrorMessage}
                error={this.state.grade_nameErrorMessage === "" ? false : true}
              >
                <option key="0" value={0}>
                  {" "}
                  --SELECT CLASS--{" "}
                </option>
                {this.state.meta_classes.map((obj, index) => (
                  <option key={obj.grade_id} value={obj.grade_id}>
                    {" "}
                    {obj.class_name}{" "}
                  </option>
                ))}
              </TextField>
              <br />
              <TextField
                id="section_name"
                name="section_name"
                label="Section"
                select
                fullWidth
                value={this.state.section_name}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                helperText={this.state.section_nameErrorMessage}
                error={
                  this.state.section_nameErrorMessage === "" ? false : true
                }
              >
                <option key="0" value={0}>
                  {" "}
                  --SELECT SECTION--{" "}
                </option>
                {this.state.meta_sections.map((obj, index) => (
                  <option key={obj.section_id} value={obj.section_id}>
                    {" "}
                    {obj.section_name}{" "}
                  </option>
                ))}
              </TextField>
              <br />
              <TextField
                autoFocus
                margin="dense"
                id="student_name"
                name="student_name"
                label="Enter Student Name"
                type="text"
                value={this.state.student_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.student_nameErrorMessage === "" ? false : true
                }
                helperText={this.state.student_nameErrorMessage}
              />
              <TextField
                margin="dense"
                id="guardian_name"
                name="guardian_name"
                label="Enter Guardian Name"
                type="text"
                value={this.state.guardian_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.guardian_nameErrorMessage === "" ? false : true
                }
                helperText={this.state.guardian_nameErrorMessage}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Relation of Guardian with Student{" "}
                </InputLabel>
                <Select
                  id="student_relation"
                  name="student_relation"
                  select
                  value={this.state.student_relation}
                  onChange={this.handleChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Uncle">Uncle</MenuItem>
                  <MenuItem value="Relative">Relative</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                id="guardian_cnic"
                name="guardian_cnic"
                label="Enter Guardian's CNIC"
                type="text"
                value={this.state.guardian_cnic || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.guardian_cnicErrorMessage === "" ? false : true
                }
                helperText={this.state.guardian_cnicErrorMessage}
              />
              <TextField
                margin="dense"
                id="password"
                name="password"
                label="Password"
                type="password"
                value={this.state.password || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.passwordErrorMessage === "" ? false : true}
                helperText={this.state.passwordErrorMessage}
              />
              <TextField
                margin="dense"
                id="student_dob"
                name="student_dob"
                label="Date of Birth"
                type="date"
                value={this.state.student_dob || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.student_dobErrorMessage === "" ? false : true}
                helperText={this.state.student_dobErrorMessage}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Student Gender
                </InputLabel>
                <Select
                  id="student_gender"
                  name="student_gender"
                  type="text"
                  value={this.state.student_gender || "M"}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value={"M"}>{"Male"}</MenuItem>;
                  <MenuItem value={"F"}>{"Female"}</MenuItem>;
                </Select>
              </FormControl>
              {/* <TextField
                id="student_gender"
                name="student_gender"
                label="Gender"
                select
                value={this.state.student_gender}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                // helperText={this.state.errorMessage}
                // error={this.state.errorMessage === "" ? false : true}
              >
                <option key="1" value="M">
                  Male
                </option>
                <option key="2" value="F">
                  Female
                </option>
              </TextField> */}
              <TextField
                margin="dense"
                id="village"
                name="village"
                label="Village"
                type="text"
                value={this.state.village || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.villageErrorMessage === "" ? false : true}
                // helperText={this.state.villageErrorMessage}
              />
              <TextField
                margin="dense"
                id="tehsil"
                name="tehsil"
                label="Tehsil"
                type="text"
                value={this.state.tehsil || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.tehsilErrorMessage === "" ? false : true}
                // helperText={this.state.tehsilErrorMessage}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">District</InputLabel>
                <Select
                  id="district"
                  name="district"
                  type="text"
                  value={this.state.district || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {districts.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  id="city"
                  name="city"
                  type="text"
                  value={this.state.city || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {cities.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Province</InputLabel>
                <Select
                  id="province"
                  name="province"
                  label="Province"
                  type="text"
                  value={this.state.province || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Punjab">Punjab</MenuItem>
                  <MenuItem value="Sindh">Sindh</MenuItem>
                  <MenuItem value="Balochistan">Balochistan</MenuItem>
                  <MenuItem value="KPK">Khyber Pakhtunkhwa</MenuItem>
                  <MenuItem value="Gilgit">Gilgit Baltistan</MenuItem>
                  <MenuItem value="AJK">AJK</MenuItem>
                  <MenuItem value="ICT">ICT</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Religion</InputLabel>
                <Select
                  id="relegion"
                  name="relegion"
                  width="100%"
                  select
                  value={this.state.relegion}
                  onChange={this.handleChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <MenuItem value="Muslim">Muslim</MenuItem>
                  <MenuItem value="Hindu">Hindu</MenuItem>
                  <MenuItem value="Christian">Christian</MenuItem>
                  <MenuItem value="Sikh">Sikh</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Disability
                </InputLabel>
                <Select
                  id="disability"
                  name="disability"
                  select
                  value={this.state.disability}
                  onChange={this.handleChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Hearing">Hearing</MenuItem>
                  <MenuItem value="Physical">Physical</MenuItem>
                  <MenuItem value="Cognitive">Cognitive</MenuItem>
                  <MenuItem value="Vision">Vision</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              {/* <br /> */}
              {/* <br /> */}
              {/* <TextField
                margin="dense"
                id="student_roll_no"
                name="student_roll_no"
                label="Roll No."
                type="text"
                value={this.state.student_roll_no || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.student_roll_noErrorMessage === "" ? false : true
                }
                helperText={this.state.student_roll_noErrorMessage}
              /> */}
              {/* <TextField
                margin="dense"
                id="enrollment_date"
                name="enrollment_date"
                label="Enrollment Date"
                type="date"
                value={this.state.enrollment_date || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.enrollment_dateErrorMessage === "" ? false : true
                }
                helperText={this.state.enrollment_dateErrorMessage}
              /> */}
              {/* student_image: "", */}
              {/* <TextField
                margin="dense"
                id="city"
                name="city"
                label="City"
                type="text"
                value={this.state.city || ""}
                fullWidth
                onChange={this.handleChange}
              /> */}
              {/* <TextField
                margin="dense"
                id="model"
                name="model"
                label="Model"
                type="text"
                value={this.state.model || ""}
                fullWidth
                onChange={this.handleChange}
              /> */}
              {/* <TextField
                margin="dense"
                id="mother_tongue"
                name="mother_tongue"
                label="Mother Tongue"
                type="text"
                value={this.state.mother_tongue || ""}
                fullWidth
                onChange={this.handleChange}
              /> */}
              {/* 
              <TextField
                id="never_been_to_school"
                name="never_been_to_school"
                label="Never been to school"
                select
                fullWidth
                value={this.state.never_been_to_school}
                style={{ width: "200px" }}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="1" value="1">
                  Yes
                </option>
                <option key="0" value="0">
                  No
                </option>
              </TextField>
              <br /> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Prior Schooling of Student
                </InputLabel>
                <Select
                  id="never_been_to_school"
                  name="never_been_to_school"
                  label="Prior Schooling"
                  type="text"
                  value={this.state.never_been_to_school}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Never been to School">Never been to School</MenuItem>
                  <MenuItem value="Drop Out">Drop Out</MenuItem>
                </Select>
              </FormControl>
              {this.state.never_been_to_school === "0" ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type of Prior Schooling
                  </InputLabel>
                  <Select
                    id="type_of_prior_schooling"
                    name="type_of_prior_schooling"
                    label="Type of Prior Schooling "
                    type="text"
                    value={this.state.type_of_prior_schooling}
                    fullWidth
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Private School">Private School</MenuItem>
                    <MenuItem value="Non-Formal">Non-Formal</MenuItem>
                    <MenuItem value="Government School">
                      Government School
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Child Labor
                </InputLabel>
                <Select
                  id="child_labor"
                  name="child_labor"
                  label="Child Labor"
                  type="text"
                  value={this.state.child_labor}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                id="child_labor"
                name="child_labor"
                label="Child Labor"
                select
                fullWidth
                value={this.state.child_labor}
                style={{ width: "200px" }}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="1" value="1">
                  Yes
                </option>
                <option key="0" value="0">
                  No
                </option>
              </TextField> */}
              {/* <br /> */}
              {/* <TextField
                id="status"
                name="status"
                label="Status"
                select
                value={this.state.status}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="Enrolled">Enrolled</option>
                <option value="DropOut">DropOut</option>
                <option value="Formal School">Formal School</option>
              </TextField> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  id="status"
                  name="status"
                  label="status"
                  type="text"
                  value={this.state.status}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Enrolled">Enrolled</MenuItem>
                  <MenuItem value="DropOut">DropOut</MenuItem>
                  <MenuItem value="Formal School">Formal School</MenuItem>
                </Select>
              </FormControl>
              {this.state.status === "DropOut" && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Drop Out Reason
                  </InputLabel>
                  <Select
                    id="dropout_reason"
                    name="dropout_reason"
                    label="dropout_reason"
                    type="text"
                    value={this.state.dropout_reason}
                    fullWidth
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Migrated">Migrated</MenuItem>
                    <MenuItem value="Child Labor">Child Labor</MenuItem>
                    <MenuItem value="Parents not willing to send child">
                      Parents not willing to send child
                    </MenuItem>
                    <MenuItem value="Student enrolled in Madrassa">
                      Student enrolled in Madrassa
                    </MenuItem>
                    <MenuItem value="Student is not willing to come to school">
                      Student is not willing to come to school
                    </MenuItem>
                    <MenuItem value="Health">Health</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ errorMessage: "", open: false });
                  this.resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
                color="primary"
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}
