import AuthService from "../../../auth/AuthService";
const Auth = new AuthService();

export const searchQuestions = (selection, question_id, s) => {
  return Auth.post("searchQuestions", {
    grade_id: selection[0],
    subject_id: selection[1],
    level_id: selection[2],
    assessment_id: selection[3],
    question_id: question_id,
    like: s,
  })
    .then((res) => {
      if (res.status) {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.questions_id,
            status: element.status,
            question_type_id: element.question_type_id,
            question_type_name: element.name,
            question: JSON.parse(element.question),
          });
        });
        return Promise.resolve(items);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const disableQuestion = (id_value, status) => {
  console.log("status", status);
  return Auth.post("crud", {
    action: "update",
    table: "questions",
    id_key: "questions_id",
    id_value: id_value,
    status: status ? 1 : 0,
  })
    .then((res) => {
      return Promise.resolve(res.data[0]);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const createVideoQuestions = (
  videoId,
  playedSeconds,
  selection,
  item
) => {
  return Auth.post("crud", {
    action: "create",
    table: "video_questions",
    video_id: videoId,
    seek: playedSeconds,
    grade_id: selection[0],
    subject_id: selection[1],
    level_id: selection[2],
    // assessment_id: selection[3],
    question_id: item,
  })
    .then((res) => {
      console.log('createVideoQuestions')
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
