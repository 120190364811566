import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
// import CreateModule from "./CreateModule";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const Auth = new AuthService();

export default function LevelDropDown({ levelProps }) {
  const classes = useStyles();

  const [grades, setGrades] = useState([]);
  const [gradeSelect, setGradeSelect] = useState("");

  const [subjects, setSubjects] = useState([]);
  const [, setSubjectSelect] = useState("");

  useEffect(() => {
    Auth.post("crud", {
      // school_id: 1,
      table: "grades",
      action: "select",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.id,
            name: element.grade_name,
          });
        });
        items.push({
          id: 0,
          name: "Add New Grade",
        });
        setGrades(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setSubjects([]);
    setSubjectSelect("");

    if (gradeSelect === "") return;

    Auth.post("crud", {
      // school_id: 1,
      id_key: "grade_id",
      id_value: gradeSelect,
      action: "select",
      table: "subjects",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.subject_id,
            name: element.subject_name,
          });
        });
        // console.log("class", res);
        // items.push({
        //   id: 0,
        //   name: "Add New Subject",
        // });
        setSubjects(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [gradeSelect]);

  const setAllLevels = (subjectSelect) => {
    if (subjectSelect === "") return;

    Auth.post("crud", {
      id_key: "subject_id",
      id_value: subjectSelect,
      action: "select",
      table: "levels",
      order: "level_order",
    })
      .then((res) => {
        if (res.status) {
          levelProps(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <>
      <Grid item xs={6} sm={4} lg={3}>
        {grades.length ? (
          <Autocomplete
            id="combo-box-demo-1"
            options={grades}
            getOptionLabel={(option) => option.name}
            autoComplete
            openOnFocus
            onChange={(event, newValue) => {
              // console.log('event', event)
              setGradeSelect(newValue ? newValue.id : "");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                style={{ float: "left" }}
                {...params}
                label="Grades List"
                variant="outlined"
                value={gradeSelect}
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} sm={4} lg={3}>
        {subjects.length ? (
          <Autocomplete
            id="combo-box-demo-2"
            options={subjects}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setAllLevels(newValue ? newValue.id : "");
              // setSubjectSelect(newValue ? newValue.id : "");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField {...params} label="Subject List" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
