import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
// import AuthService from "../auth/AuthService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { QuestionCard } from "./lib/QuestionCard";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  searchQuestions,
  disableQuestion,
  createVideoQuestions,
} from "./lib/sync/questionsLib";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  card: {
    // maxWidth: 600,
    marginBottom: 10,
  },
  deleted: {
    backgroundColor: "#ffab82",
  },
  img: {
    width: 50,
  },
}));

export default function QuestionSearch(props) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [s, setS] = useState("");

  const handleChange = (event) => {
    let arr = event.target.name.split("--");
    disableQuestion(arr[1], event.target.checked)
      .then((data) => {
        let question_id = "";
        searchQuestions(props.selection, question_id, s)
          .then((items) => {
            setQuestions(items);
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleClick = (item) => {
    createVideoQuestions(
      props.videoId,
      props.playedSeconds,
      props.selection,
      item
    )
      .then((data) => {
        props.itemSaved(data, true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (!props.popupHide) return;
    console.log("props.selection", props.selection);
    if (props.selection.length === 0) {
      setQuestions([]);
      return;
    }
    let question_id = "";

    searchQuestions(props.selection, question_id, s)
      .then((items) => {
        setQuestions(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.playedSeconds, props.selection, s, props.popupHide]);

  return (
    <Container maxWidth="lg">
      <Grid item xs={6} sm={4} lg={3}>
        <TextField
          id="search-textarea"
          label="Search Question"
          placeholder="eg: for"
          multiline
          onKeyUp={(e) => {
            console.log("e.target.value", e.target.value);
            // setBlock(false);
            setS(e.target.value);
          }}
        />
        <br />
        <br />
      </Grid>
      {questions.map((item, index) => {
        return (
          <Card
            key={index}
            className={`${classes.card} ${
              item.status === "0" ? classes.deleted : ""
            }`}
          >
            <QuestionCard item={item} key={index} />
            <CardActions>
              {props.videoId && (
                <Button
                  onClick={() => handleClick(item.id)}
                  size="small"
                  color="primary"
                >
                  Select
                </Button>
              )}
              {!props.videoId && item.status === "1" && (
                <Button
                  onClick={() => props.handleEdit(item)}
                  size="small"
                  color="primary"
                >
                  Edit
                </Button>
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={item.status === "1"}
                    onChange={handleChange}
                    name={`status--${item.id}`}
                    color="primary"
                  />
                }
                label="Enabled"
              />
            </CardActions>
          </Card>
        );
      })}
    </Container>
  );
}
