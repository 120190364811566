import React, { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import { makeStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
// import Typography from "@material-ui/core/Typography";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Switch from '@material-ui/core/Switch';

import AssessmentIcon from "@material-ui/icons/Assessment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import Confirmation from "./confirmation";
// import IconButton from "@material-ui/core/IconButton";
// import Typography from '@material-ui/core/Typography';
// import CircularProgress from "@material-ui/core/CircularProgress";

// import Box from "@material-ui/core/Box";

const CARD = "card";
// const style = {
//   border: "1px dashed gray",
//   padding: "0.5rem 1rem",
//   marginBottom: ".5rem",
//   backgroundColor: "white",
//   cursor: "move",
// };
// const useStyles = makeStyles((theme) => ({
//   downlaoding: {
//     backgroundColor: "#3f51b5",
//   },
//   cursor: { cursor: "pointer" },
// }));
// function CircularProgressWithLabel(props) {
//   return (
//     <Box position="relative" display="inline-flex">
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         top={0}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         <Typography
//           variant="caption"
//           component="div"
//           color="textSecondary"
//         >{`${Math.round(props.value)}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }
export const Card = ({
  id,
  index,
  moveCard,
  videoItem,
  itemDeleted,
  itemSoftDelete,
}) => {
  const ref = useRef(null);
  // const classes = useStyles();
  const [, setProgress] = React.useState(0);

  useEffect(() => {
    let percent = 0;
    if (videoItem.status === "1" && videoItem.total_size !== "0") {
      percent = (videoItem.downloaded_size / videoItem.total_size) * 100;
    }
    setProgress(percent);

    // @TODO FIX
    // setInterval(function () {
    //   Auth.post("crud", {
    //     action: "select",
    //     table: "videos",
    //     id_key: "id",
    //     id_value: videoItem.id,
    //   })
    //     .then((res) => {
    //       if (res.data[0].status === "1" && res.data[0].total_size !== "0") {
    //         percent = (res.data[0].downloaded_size / res.data[0].total_size) * 100;
    //       }
    //       setProgress(percent);
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     });
    // }, 3000);
  }, [videoItem]);

  const mbConvert = (sizeInBytes) => {
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return sizeInMB + "MB";
  };
  const videoVersion = (video_version) => {
    return video_version !== "0" ? `, Video Version: ${video_version}` : "";
  };
  const videoName = (name) => {
    return name.split("/").pop();
  };

  const [, drop] = useDrop({
    accept: CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <ListItem ref={ref} style={{ opacity }}>
      <ListItemAvatar>
        <Avatar>
          {videoItem.status === "2" ? <AssessmentIcon /> : <VideoLibraryIcon />}
          {/* <FolderIcon /> */}
        </Avatar>
      </ListItemAvatar>

      <ListItemText style={{color: "#111"}}
        primary={
          videoItem.status === "2"
            ? "Assessment"
            : `Video: ${videoName(videoItem.video_link)} (${mbConvert(
                videoItem.total_size
              )})${videoVersion(videoItem.video_version)}`
        }
        secondary={videoItem.video_link}
      />

      <ListItemSecondaryAction>
        <Confirmation
          deleteConfirm={() => {
            itemDeleted(videoItem.id);
          }}
        />
        
        {/* <IconButton
          onClick={() => {
            itemSoftDelete(videoItem.id, videoItem.deleted);
          }}
          edge="end"
          aria-label="delete"
        >
          <HighlightOffIcon />
        </IconButton> */}
        <Switch
            edge="end"
            onChange={() => {
              itemSoftDelete(videoItem.id, videoItem.deleted);
            }}
            checked={parseInt(videoItem.deleted) === 0 ? true : false}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
      </ListItemSecondaryAction>
    </ListItem>
  );
  // return (<div ref={ref} style={{ ...style, opacity }}>
  // 		{text}
  // 	</div>);
};
