import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../../auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CreateModule from "../CreateModule";
import config from "../../../config";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const Auth = new AuthService();

export default function QuestionType({
  questionTypeId,
  grade,
  subject,
  level,
  defaultValue
}) {
  const classes = useStyles();
  const [table, setTable] = useState("");
  const [keyName, setKeyName] = useState("");
  // const [defaultValue, setDefaultValue] = useState(() => {
  //   return {
  //     id: "",
  //     name: "",
  //   };
  // });

  const [questionTypes, setQuestionTypes] = useState([]);
  const [select, setSelect] = useState("");
  // const [topics, setTopics] = useState([]);
  // const [topicsSelect, setTopicsSelect] = useState("");

  const setItems = (items, id, name) => {
    let lastItem = items.pop();
    items.push({
      id: id,
      name: name,
    });
    items.push(lastItem);
    return items;
  };

  const dataSaved = ({ id, name, table }) => {
    setQuestionTypes(setItems(questionTypes, id, name));
    setTable("");
    setKeyName("");
  };

  useEffect(() => {
    // if (select === "") return;
    Auth.post("crud", {
      table: "question_types",
      keys_object: JSON.stringify({
        grade_id: grade,
        subject_id: subject,
        level_id: level,
      }),
      action: "select",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.id,
            name: element.name,
          });
        });
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Question Group",
          });
        }
        setQuestionTypes(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [grade, subject, level]);

  // useEffect(() => {
  //   questionTypeId(select);
  // }, [select, questionTypeId]);

  return (
    <>
      <CreateModule
        // style={{ float: "left" }}
        hidden={true}
        table={table}
        gradeSelect={grade}
        subjectSelect={subject}
        levelSelect={level}
        topicsSelect={select}
        dataSaved={dataSaved}
        setTable={setTable}
        keyName={keyName}
      />

      <Grid item xs={6} sm={4} lg={3}>
        {questionTypes.length ? (
          <Autocomplete
            // filterSelectedOptions={true}
            id="combo-box-demo-2"
            options={questionTypes}
            getOptionLabel={(option) => option.name}
            defaultValue={defaultValue}
            onChange={(event, newValue) => {
              setSelect(newValue ? newValue.id : "");
              questionTypeId(newValue ? newValue.id : "");

              if (newValue && newValue.id === 0) {
                setTable("question_types");
                setKeyName("name");
              }
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Question Groups List"
                variant="outlined"
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
