import React, { Component } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import UpdateIcon from "@material-ui/icons/Update";
import Tooltip from "@material-ui/core/Tooltip";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthService from "../auth/AuthService";
import Message from "../common/Message";
import districts from "../../constants/districts";
import cities from "../../constants/cities";

export default class AddStudent extends Component {
  constructor(props) {
    super(props);
    console.log("data from props", props.student);
    this.state = {
      student_id: props.student.student_id,
      grade_id: props.student.grade_id,
      // grade_name: props.student.grade_id,
      section_id: props.student.section_id,
      section_name: props.student.section_id,
      student_name: props.student.student_name,
      student_gender: props.student.student_gender,
      student_dob: props.student.student_dob,
      // student_roll_no: props.student.,
      guardian_name: props.student.guardian_name,
      guardian_cnic: props.student.guardian_cnic,
      student_image: props.student.student_image,
      status: props.student.status,
      district: props.student.district,
      city: props.student.city,
      mother_tongue: props.student.mother_tongue,
      never_been_to_school: props.student.never_been_to_school,
      type_of_prior_schooling: props.student.type_of_prior_schooling,
      child_labor: props.student.child_labor,
      // password: "", //props.student.password,
      enrollment_date: props.student.enrollment_date,
      dropout_reason: props?.student?.dropout_reason,
      open: false,

      meta_classes: [],
      meta_sections: [],

      errorMessage: "",
      grade_nameErrorMessage: "",
      section_nameErrorMessage: "",
      student_nameErrorMessage: "",
      student_genderErrorMessage: "",
      student_dobErrorMessage: "",
      // student_roll_noErrorMessage: "",
      guardian_nameErrorMessage: "",
      guardian_cnicErrorMessage: "",
      student_imageErrorMessage: "",
      // passwordErrorMessage: "",
      enrollment_dateErrorMessage: "",
      serverError: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }
  // const[open, setOpen] = React.useState (false);

  handleClickOpen() {
    // this.resetForm();
    this.Auth.post("classes")
      .then((data) => {
        this.setState({
          meta_classes: data.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });

    //// get sections
    this.Auth.post("sections", {
      grade_id: this.props.student.grade_id,
    })
      .then((data) => {
        this.setState({
          meta_sections: data.data,
        });
        console.log(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    this.setState({ open: true });
  }

  resetForm() {
    console.log("inside reset");
    this.setState({
      open: false,
      errorMessage: "",
      grade_nameErrorMessage: "",
      section_nameErrorMessage: "",
      student_nameErrorMessage: "",
      student_genderErrorMessage: "",
      student_dobErrorMessage: "",
      // student_roll_noErrorMessage: "",
      guardian_nameErrorMessage: "",
      guardian_cnicErrorMessage: "",
      student_imageErrorMessage: "",
      // passwordErrorMessage: "",
      enrollment_dateErrorMessage: "",
      serverError: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('this.state', this.state)

    this.setState(
      {
        // grade_nameErrorMessage:
        //   this.state.grade_name === "" ? "Grade is required " : "",
        section_nameErrorMessage:
          this.state.section_name === "" ? "Section is required " : "",
        student_nameErrorMessage:
          this.state.student_name === "" ? "Name is required " : "",
        student_genderErrorMessage:
          this.state.student_gender === "" ? "Gender is required " : "",
        student_dobErrorMessage:
          this.state.student_dob === "" ? "DOB is required " : "",
        guardian_nameErrorMessage:
          this.state.guardian_name === "" ? "Guradian name is required " : "",
        guardian_cnicErrorMessage:
          this.state.guardian_cnic === "" ? "Guardian CNIC is required " : "",
        student_imageErrorMessage:
          this.state.student_image === "" ? "Student Image is required " : "",
        // passwordErrorMessage:
        //   this.state.password === "" ? "Password is required " : "",
        enrollment_dateErrorMessage:
          this.state.enrollment_date === "" ? "Enrollment is required " : "",
      },
      () => {
        if (
          this.state.grade_nameErrorMessage.length > 0 ||
          this.state.section_nameErrorMessage.length > 0 ||
          this.state.student_nameErrorMessage.length > 0 ||
          this.state.student_genderErrorMessage.length > 0 ||
          this.state.student_dobErrorMessage.length > 0 ||
          // this.state.student_roll_noErrorMessage.length > 0 ||
          this.state.guardian_nameErrorMessage.length > 0 ||
          this.state.guardian_cnicErrorMessage.length > 0 ||
          // this.state.student_imageErrorMessage.length > 0 ||
          // this.state.passwordErrorMessage.length > 0 ||
          this.state.enrollment_dateErrorMessage.length > 0
        ) {
          this.setState({
            serverError: "Invalid data provided",
          });

          console.log(this.state.grade_nameErrorMessage);
          console.log(this.state.section_nameErrorMessage);
          console.log(this.state.student_nameErrorMessage);
          console.log("gender", this.state.student_gender);
          console.log(this.state.student_genderErrorMessage);
          console.log(this.state.studetn_dobErrorMessage);
          console.log(this.state.guardian_nameErrorMessage);
          console.log(this.state.guardian_cnicErrorMessage);
          console.log(this.state.passwordErrorMessage);
          console.log(this.state.enrollment_dateErrorMessage);

          console.log("Invalid data provided");
        } else {
          this.Auth.post("crud", {
            id_key: "student_id",
            id_value: this.state.student_id,
            table: "student",
            action: "update",
            grade_id: this.state.grade_id,
            section_id: this.state.section_id,
            student_name: this.state.student_name,
            student_gender: this.state.student_gender,
            student_dob: this.state.student_dob,
            // student_roll_no: this.state.student_roll_no,
            guardian_name: this.state.guardian_name,
            guardian_cnic: this.state.guardian_cnic,
            student_image: this.state.student_image,
            // password: this.state.password,
            enrollment_date: this.state.enrollment_date,
            district: this.state.district,
            city: this.state.city,
            mother_tongue: this.state.mother_tongue,
            never_been_to_school: this.state.never_been_to_school,
            type_of_prior_schooling: this.state.type_of_prior_schooling,

            child_labor: this.state.child_labor,
            status: this.state.status,
          })
            .then((res) => {
              console.log(res.data.error);
              if (res.data.error) {
                this.setState({ serverError: res.data.error });
              } else {
                this.setState({
                  // grade_name: "",
                  section_name: "",
                  grade_id: "",
                  section_id: "",
                  student_name: "",
                  student_gender: "",
                  student_dob: "",
                  // student_roll_no: "",
                  guardian_name: "",
                  guardian_cnic: "",
                  student_image: "",
                  // password: "",
                  enrollment_date: "",
                  open: false,
                });
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.setState({
                errorMessage: "some error occurred please try again",
              });
            });
        }
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    // if (name === "grade_name") {
    //   console.log("inside grade name");

    //   this.Auth.post("sections", {
    //     grade_id: value,
    //   })
    //     .then((data) => {
    //       this.setState({
    //         section_id: 0,
    //         section_name: "",
    //         meta_sections: data.data,
    //       });
    //       console.log(data);
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }
  }

  render() {
    const { plus } = this.props;
    return (
      <div>
        {plus ? (
          <Tooltip title="Edit">
            <UpdateIcon
              style={{ cursor: "pointer" }}
              variant="outlined"
              onClick={this.handleClickOpen}
            />
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Open form dialog
          </Button>
        )}
        <br style={{ clear: "both" }} />

        <form>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Update Student</DialogTitle>
            <Message msg={this.state.serverError} type="error" />
            <DialogContent>
              {/* <TextField
                id="grade_name"
                name="grade_name"
                label="Grade"
                select
                fullWidth
                value={this.state.grade_name}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                helperText={this.state.grade_nameErrorMessage}
                error={this.state.grade_nameErrorMessage === "" ? false : true}
              >
                <option key="0" value={0}>
                  {" "}
                  --SELECT CLASS--{" "}
                </option>
                {this.state.meta_classes.map((obj, index) => (
                  <option key={obj.grade_id} value={obj.grade_id}>
                    {" "}
                    {obj.class_name}{" "}
                  </option>
                ))}
              </TextField> */}
              {/* <br />
              <TextField
                id="section_name"
                name="section_name"
                label="Section"
                select
                value={this.state.section_name}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                helperText={this.state.section_nameErrorMessage}
                error={
                  this.state.section_nameErrorMessage === "" ? false : true
                }
              >
                <option key="0" value={0}>
                  {" "}
                  --SELECT SECTION--{" "}
                </option>
                {this.state.meta_sections.map((obj, index) => (
                  <option key={obj.section_id} value={obj.section_id}>
                    {" "}
                    {obj.section_name}{" "}
                  </option>
                ))}
              </TextField> */}
              <br />
              <TextField
                autoFocus
                margin="dense"
                id="student_name"
                name="student_name"
                label="Full Name"
                type="text"
                value={this.state.student_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.student_nameErrorMessage === "" ? false : true
                }
                helperText={this.state.student_nameErrorMessage}
              />
              {/* <TextField
                margin="dense"
                id="password"
                name="password"
                label="Password"
                type="password"
                value={this.state.password || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.passwordErrorMessage === "" ? false : true}
                helperText={this.state.passwordErrorMessage}
              /> */}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Student Gender
                </InputLabel>
                <Select
                  id="student_gender"
                  name="student_gender"
                  type="text"
                  value={this.state.student_gender || "M"}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value={"M"}>{"Male"}</MenuItem>;
                  <MenuItem value={"F"}>{"Female"}</MenuItem>;
                </Select>
              </FormControl>
              <br />
              <TextField
                margin="dense"
                id="student_dob"
                name="student_dob"
                label="DOB"
                type="date"
                value={this.state.student_dob || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.student_dobErrorMessage === "" ? false : true}
                helperText={this.state.student_dobErrorMessage}
              />
              {/* <TextField
                margin="dense"
                id="student_roll_no"
                name="student_roll_no"
                label="Roll No."
                type="text"
                value={this.state.student_roll_no || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.student_roll_noErrorMessage === "" ? false : true
                }
                helperText={this.state.student_roll_noErrorMessage}
              /> */}
              <TextField
                margin="dense"
                id="guardian_name"
                name="guardian_name"
                label="Guardian Name"
                type="text"
                value={this.state.guardian_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.guardian_nameErrorMessage === "" ? false : true
                }
                helperText={this.state.guardian_nameErrorMessage}
              />
              <TextField
                margin="dense"
                id="guardian_cnic"
                name="guardian_cnic"
                label="Guardian CNIC"
                type="text"
                value={this.state.guardian_cnic || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.guardian_cnicErrorMessage === "" ? false : true
                }
                helperText={this.state.guardian_cnicErrorMessage}
              />
              <TextField
                margin="dense"
                id="enrollment_date"
                name="enrollment_date"
                label="Enrollment Date"
                type="date"
                value={this.state.enrollment_date || ""}
                fullWidth
                onChange={this.handleChange}
                error={
                  this.state.enrollment_dateErrorMessage === "" ? false : true
                }
                helperText={this.state.enrollment_dateErrorMessage}
              />

              {/* student_image: "", */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">District</InputLabel>
                <Select
                  id="district"
                  name="district"
                  type="text"
                  value={this.state.district || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {districts.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  id="city"
                  name="city"
                  type="text"
                  value={this.state.city || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {cities.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Prior Schooling of Student
                </InputLabel>
                <Select
                  id="never_been_to_school"
                  name="never_been_to_school"
                  label="Prior Schooling"
                  type="text"
                  value={this.state.never_been_to_school}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Never been to School">Never been to School</MenuItem>
                  <MenuItem value="Drop Out">Drop Out</MenuItem>
                </Select>
              </FormControl>
              {this.state.never_been_to_school === "0" ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type of Prior Schooling
                  </InputLabel>
                  <Select
                    id="type_of_prior_schooling"
                    name="type_of_prior_schooling"
                    label="Type of Prior Schooling "
                    type="text"
                    value={this.state.type_of_prior_schooling}
                    fullWidth
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Private School">Private School</MenuItem>
                    <MenuItem value="Non-Formal">Non-Formal</MenuItem>
                    <MenuItem value="Government School">
                      Government School
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Never been to school
                </InputLabel>
                <Select
                  id="never_been_to_school"
                  name="never_been_to_school"
                  label="Never Been to School"
                  type="text"
                  value={this.state.never_been_to_school}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Child Labor
                </InputLabel>
                <Select
                  id="child_labor"
                  name="child_labor"
                  label="Child Labor"
                  type="text"
                  value={this.state.child_labor}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>

              <br />
              {/* <TextField
                id="status"
                name="status"
                label="Status"
                select
                value={this.state.status}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="Enrolled">Enrolled</option>
                <option value="DropOut">DropOut</option>
                <option value="Formal School">Formal School</option>
              </TextField> */}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  id="status"
                  name="status"
                  label="status"
                  type="text"
                  value={this.state.status}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Enrolled">Enrolled</MenuItem>
                  <MenuItem value="DropOut">DropOut</MenuItem>
                  <MenuItem value="Formal School">Formal School</MenuItem>
                </Select>
              </FormControl>

              {this.state.status === "DropOut" && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Drop Out Reason
                  </InputLabel>
                  <Select
                    id="dropout_reason"
                    name="dropout_reason"
                    label="dropout_reason"
                    type="text"
                    value={this.state.dropout_reason}
                    fullWidth
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Migrated">Migrated</MenuItem>
                    <MenuItem value="Child Labor">Child Labor</MenuItem>
                    <MenuItem value="Parents not willing to send child">
                      Parents not willing to send child
                    </MenuItem>
                    <MenuItem value="Student enrolled in Madrassa">
                      Student enrolled in Madrassa
                    </MenuItem>
                    <MenuItem value="Student is not willing to come to school">
                      Student is not willing to come to school
                    </MenuItem>
                    <MenuItem value="Health">Health</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              )}

              <br />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ errorMessage: "", open: false });
                  this.resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
                color="primary"
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}
