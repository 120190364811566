import React, { useEffect, useState, useCallback } from "react";
import AuthService from "./auth/AuthService";
import SessionCloseDropDown from "./common/SessionCloseDropDown";
import { Grid } from "@material-ui/core";
import Promotion from "./promotion/Promotion";

const Auth = new AuthService();

export default function SessionClose({ attendance }) {
  // const ref = React.useRef(null);
  const [selection, setSelection] = useState(["", "", ""]);
  const [promotion, setPromotion] = useState(false);
  const [message, setMessage] = useState("");

  // const [things, setThings] = useContext(ThingsContext);

  // const classes = useStyles();

  useEffect(() => {
    if (!Auth.loggedIn()) return window.location.replace("/");
  }, []);

  // const playVideo = (name) => {
  //   setMyVideo(name);
  // };

  // const playVideoFunc = useCallback((item) => {
  //   // setAssessment("");
  //   setVideoId(item.video_id);
  //   if (parseInt(item.downloaded_size) < parseInt(item.total_size)) {
  //     sasLink(item.video_id)
  //       .then((data) => {
  //         playVideo(`${data.link}`);
  //       })
  //       .catch((e) => {
  //         console.log("e", e);
  //       });
  //   } else {
  //     playVideo(`${config.videoEndPoint}${item.video_id}`);
  //   }

  //   setSeek(parseInt(item.seek ? item.seek : 0));
  // }, []);

  const process = useCallback(() => {
    setPromotion(false);
    Auth.post("getRunningVideo", {
      grade_id: selection[0],
      section_id: selection[1],
      subject_id: "all",
      first: true,
    })
      .then((res) => {
        console.log("res", res);
        if (!res.status) {
          setPromotion(true);
        } else {
          let msg = `${
            res.data[0].status === "2"
              ? res.data[0].assessment_name
              : res.data[0].video_link.split("/").pop()
          } of subject ${res.data[0].subject_name} is not done yet.`;
          // console.log("##", res.data[0].assessment_name);
          // console.log("##", res.data[0].subject_name);
          // console.log("##", res.data[0].status);
          setMessage(msg);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [selection]);

  useEffect(() => {
    if (selection[0] && selection[1]) {
      // setThings(selection);
      setMessage("");
      process();
    }
  }, [process, selection]);

  // useEffect(() => {
  //   ref.current && ref.current.seekTo(seek, "seconds");
  // }, [seek]);

  // useEffect(() => {
  //   console.log("things...", things);
  //   if (things.length === 4 && things[3] !== null) {
  //     let item = things[3];
  //     playVideoFunc(item);
  //   }
  // }, [things, playVideoFunc]);

  return (
    <>
      <Grid item xs={12}>
        <h1>Close Session </h1>
      </Grid>
      <SessionCloseDropDown selection={setSelection} />

      {selection[0] && selection[1] ? (
        <>
          {promotion && (
            <Promotion grade={selection[0]} section={selection[1]} />
          )}
          <Grid item xs={12}>
            <p>{message}</p>
          </Grid>
        </>
      ) : (
        ""
      )}
    </>
  );
}
