import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthService from "../auth/AuthService";
import Message from "../common/Message";
import Autocomplete from "@material-ui/lab/Autocomplete";
import districts from "../../constants/districts";
export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      role: 1,
      cnic: "",
      city: "",
      contact: "",
      takmiltraining: "",
      tehsil: "",
      village: "",
      council: "",
      qualification: "",
      currentResidence: "",
      durationexp: "",
      province: "",
      certified: "",
      gender: "1",
      status: "",
      education: "",
      experience: "",
      training: "",
      belongs_to_community: "",
      attendence: "",
      dob: "",
      open: false,
      errorMessage: "",
      first_nameErrorMessage: "",
      last_nameErrorMessage: "",
      emailErrorMessage: "",
      schoolErrorMessage: "",
      passwordErrorMessage: "",
      cnicErrorMessage: "",
      contactErrorMessage: "",
      addressErrorMessage: "",
      dobErrorMessage: "",
      serverError: "",
      schools: [],
      school_id: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }
  // const[open, setOpen] = React.useState (false);

  handleClickOpen() {
    this.resetForm();

    this.Auth.post("getSchools", {
      // school_id: 1,
    })
      .then((res) => {
        if (res.status) {
          this.setState({ open: true, schools: res.data });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  resetForm() {
    this.setState({
      email: "",
      first_name: "",
      city: "",
      password: "",
      role: 1,
      open: false,
      errorMessage: "",
      first_nameErrorMessage: "",
      last_nameErrorMessage: "",
      emailErrorMessage: "",
      passwordErrorMessage: "",
      cnicErrorMessage: "",
      contactErrorMessage: "",
      addressErrorMessage: "",
      dobErrorMessage: "",
      serverError: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState(
      {
        first_nameErrorMessage:
          this.state.first_name === "" ? "Frist Name is required " : "",
        last_nameErrorMessage:
          this.state.last_name === "" ? "Last Name is required " : "",
        emailErrorMessage:
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.state.email
          )
            ? ""
            : "Invalid email address",
        passwordErrorMessage:
          this.state.password === "" ? "Password is required" : "",
        schoolErrorMessage:
          this.state.school_id === "" ? "School ID is required" : "",
        cnicErrorMessage: this.state.cnic === "" ? "CNIC ID is required" : "",
        contactErrorMessage:
          this.state.contact === "" ? "Contact is required" : "",
        addressErrorMessage:
          this.state.address === "" ? "Address is required" : "",
        dobErrorMessage: this.state.dob === "" ? "DOB is required" : "",
      },
      () => {
        if (
          this.state.first_nameErrorMessage.length > 0 ||
          this.state.last_nameErrorMessage.length > 0 ||
          this.state.emailErrorMessage.length > 0 ||
          this.state.passwordErrorMessage.length > 0 ||
          this.state.cnicErrorMessage.length > 0 ||
          this.state.contactErrorMessage.length > 0 ||
          this.state.addressErrorMessage.length > 0 ||
          this.state.dobErrorMessage.length > 0 ||
          this.state.schoolErrorMessage.length > 0
        ) {
          // this.setState({
          //   serverError: "Invalid data provided",
          // });
          console.log("Invalid data provided");
        } else {
          this.Auth.post("signup", {
            school_id: this.state.school_id,
            email: this.state.email,
            user_name: this.state.first_name,
            first_name: this.state.first_name,
            city: this.state.city,
            last_name: this.state.last_name,
            password: this.state.password,
            role: this.state.role,
            cnic: this.state.cnic,
            contact: this.state.contact,
            address: this.state.address,
            gender: this.state.gender,
            status: this.state.status,
            certified: this.state.certified,
            durationexp: this.state.durationexp,
            village: this.state.village,
            tehsil: this.state.tehsil,
            council: this.state.council,
            takmiltraining: this.state.takmiltraining,
            qualification: this.state.qualification,
            province: this.state.province,
            currentResidence: this.state.currentResidence,
            district: this.state.district,
            dob: this.state.dob,
            education: this.state.education,
            experience: this.state.experience,
            training: this.state.training,
            belongs_to_community: this.state.belongs_to_community,
            attendence: this.state.attendence,
          })
            .then((data) => {
              console.log(data);
              if (data.error) {
                this.setState({ serverError: data.message });
              } else {
                this.setState({
                  errorMessage: "",
                  email: "",
                  first_name: "",
                  city: "",
                  password: "",
                  open: false,
                  cnic: "",
                  contact: "",
                  address: "",
                  gender: "1",
                  dob: "",
                  education: "",
                  experience: "",
                  training: "",
                  belongs_to_community: "",
                  attendence: "",
                });
                // this.props.propAddLink(data.user);
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.setState({
                serverError: "some error occurred please try again",
              });
            });
        }
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { plus } = this.props;
    return (
      <div>
        {plus ? (
          <Tooltip title="Add User">
            <AddCircleIcon
              style={{
                cursor: "pointer",
                fontSize: 30,
                float: "right",
                color: "#FF6162",
              }}
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            />
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Open form dialog
          </Button>
        )}
        <br style={{ clear: "both" }} />

        <form>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Create User</DialogTitle>
            {/* {this.state.serverError === "" ? (
              ""
            ) : (
                <div className="alert alert-danger" role="alert">
                  {this.state.serverError}
                </div>
              )} */}
            <Message msg={this.state.serverError} type="error" />
            <DialogContent>
              <Autocomplete
                id="schools-list"
                options={this.state.schools}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  this.setState({
                    school_id: newValue.id,
                  });
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={this.state.school_id}
                    label="Schools List"
                    variant="outlined"
                    error={this.state.schoolErrorMessage === "" ? false : true}
                    helperText={this.state.schoolErrorMessage}
                  />
                )}
              />
              <TextField
                autoFocus
                margin="dense"
                id="first_name"
                name="first_name"
                label="First Name"
                type="text"
                value={this.state.first_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.first_nameErrorMessage === "" ? false : true}
                helperText={this.state.first_nameErrorMessage}
              />

              <TextField
                margin="dense"
                id="last_name"
                name="last_name"
                label="Last Name"
                type="text"
                value={this.state.last_name || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.last_nameErrorMessage === "" ? false : true}
                helperText={this.state.last_nameErrorMessage}
              />
              <TextField
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                value={this.state.email || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.emailErrorMessage === "" ? false : true}
                helperText={this.state.emailErrorMessage}
              />
              <TextField
                margin="dense"
                id="password"
                name="password"
                label="Password"
                type="password"
                value={this.state.password || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.passwordErrorMessage === "" ? false : true}
                helperText={this.state.passwordErrorMessage}
              />
              <TextField
                margin="dense"
                id="cnic"
                name="cnic"
                label="CNIC"
                type="text"
                value={this.state.cnic || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.cnicErrorMessage === "" ? false : true}
                helperText={this.state.cnicErrorMessage}
              />

              <TextField
                margin="dense"
                id="contact"
                name="contact"
                label="Contact Number"
                type="text"
                value={this.state.contact || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.contactErrorMessage === "" ? false : true}
                helperText={this.state.contactErrorMessage}
              />
              <TextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                value={this.state.address || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.addressErrorMessage === "" ? false : true}
                helperText={this.state.addressErrorMessage}
              />
              <TextField
                margin="dense"
                id="dob"
                name="dob"
                label="DOB"
                type="date"
                value={this.state.dob || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.dobErrorMessage === "" ? false : true}
                helperText={this.state.dobErrorMessage}
              />
              <TextField
                id="gender"
                name="gender"
                label="Gender"
                select
                fullWidth
                value={this.state.gender}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="Male" value="1">
                  Male
                </option>
                <option key="Female" value="2">
                  Female
                </option>
              </TextField>
              {/* <TextField
                id="province"
                name="province"
                label="Province"
                select
                value={this.state.province}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                // helperText={this.state.provinceErrorMessage}
                // error={this.state.provinceErrorMessage === "" ? false : true}
              > */}
              <TextField
                margin="dense"
                id="village"
                name="village"
                label="Village"
                type="text"
                value={this.state.village || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.villageErrorMessage === "" ? false : true}
                // helperText={this.state.villageErrorMessage}
              />
              <TextField
                margin="dense"
                id="council"
                name="council"
                label="Union Council"
                type="text"
                value={this.state.council || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.councilErrorMessage === "" ? false : true}
                // helperText={this.state.councilErrorMessage}
              />
              <TextField
                margin="dense"
                id="tehsil"
                name="tehsil"
                label="Tehsil"
                type="text"
                value={this.state.tehsil || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.tehsilErrorMessage === "" ? false : true}
                // helperText={this.state.tehsilErrorMessage}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">District</InputLabel>
                <Select
                  id="district"
                  name="district"
                  type="text"
                  value={this.state.district || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {districts.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Province</InputLabel>
                <Select
                  id="province"
                  name="province"
                  label="Province"
                  type="text"
                  value={this.state.province || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Punjab">Punjab</MenuItem>
                  <MenuItem value="Sindh">Sindh</MenuItem>
                  <MenuItem value="Balochistan">Balochistan</MenuItem>
                  <MenuItem value="KPK">Khyber Pakhtunkhwa</MenuItem>
                  <MenuItem value="Gilgit">Gilgit Baltistan</MenuItem>
                  <MenuItem value="AJK">AJK</MenuItem>
                  <MenuItem value="ICT">ICT</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Highest Qualification
                </InputLabel>
                <Select
                  id="qualification"
                  name="qualification"
                  type="text"
                  value={this.state.qualification || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Bachelors">Bachelors</MenuItem>
                  <MenuItem value="Masters">Masters</MenuItem>
                  <MenuItem value="Doctorate">Doctorate</MenuItem>
                  <MenuItem value="Less thenIntermediate">
                    Less thenIntermediate
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Training Experience
                </InputLabel>
                <Select
                  id="training"
                  name="training"
                  type="text"
                  value={this.state.training || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Duration of Teaching Experience
                </InputLabel>
                <Select
                  id="durationexp"
                  name="durationexp"
                  type="text"
                  value={this.state.durationexp || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Does this user have training or Certification?
                </InputLabel>
                <Select
                  id="certified"
                  name="certified"
                  type="text"
                  value={this.state.certified || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Has the teacher completed TAKMIL Training of Non-formal
                  education?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="takmiltraining"
                  type="text"
                  value={this.state.takmiltraining || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Does the teacher living in the community where school is
                  established?
                </InputLabel>
                <Select
                  id="currentResidence"
                  name="currentResidence"
                  type="text"
                  value={this.state.currentResidence || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>

              {/* <TextField
                margin="dense"
                id="education"
                name="education"
                label="Education"
                type="text"
                value={this.state.education || ""}
                fullWidth
                onChange={this.handleChange}
              />
              <TextField
                margin="dense"
                id="experience"
                name="experience"
                label="Experience"
                type="text"
                value={this.state.experience || ""}
                fullWidth
                onChange={this.handleChange}
              /> */}
              {/* <TextField
                margin="dense"
                id="attendence"
                name="attendence"
                label="Attendence"
                type="text"
                value={this.state.attendence || ""}
                fullWidth
                onChange={this.handleChange}
              /> */}
              {/* <TextField
                id="training"
                name="training"
                label="Training"
                select
                value={this.state.training}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="1" value="1">
                  Yes
                </option>
                <option key="0" value="0">
                  No
                </option>
              </TextField> */}
              {/* <br /> */}
              {/* <TextField
                id="belongs_to_community"
                name="belongs_to_community"
                label="Belongs to community"
                select
                value={this.state.belongs_to_community}
                style={{ width: "200px" }}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="1" value="1">
                  Yes
                </option>
                <option key="0" value="0">
                  No
                </option>
              </TextField> */}
              <br />

              <br />
              <TextField
                id="role"
                name="role"
                label="Role"
                select
                fullWidth
                value={this.state.role}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                // helperText={this.state.errorMessage}
                // error={this.state.errorMessage === "" ? false : true}
              >
                <option key="Admin" value="1">
                  Admin
                </option>
                <option key="Teacher" value="2">
                  Teacher
                </option>
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ errorMessage: "", open: false });
                  this.resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} color="primary">
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}
