import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Pagination from "@material-ui/lab/Pagination";
import TableRow from "@material-ui/core/TableRow";
// import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

import AuthService from "./auth/AuthService";
import { Button } from "@material-ui/core";

const Auth = new AuthService();

const columns = [
  {
    id: "class_name",
    label: "Class Name",
    // minWidth: 170,
  },
  {
    id: "section_name",
    label: "Section Name",
    // minWidth: 170,
  },
  {
    id: "student_name",
    label: "Student Name",
    // minWidth: 170,
  },
  {
    id: "video_link",
    label: "Video Name",
    // minWidth: 100,
  },
  {
    id: "seek",
    label: "seek",
    // minWidth: 170,
  },
  {
    id: "done",
    label: "done",
    // minWidth: 170,
  },
  {
    id: "duration",
    label: "duration",
    // minWidth: 170,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [searchClass, setSearchClass] = React.useState("");
  const [searchSection, setSearchSection] = React.useState("");
  const [grades, setGrades] = React.useState([]);
  const [sections, setSections] = React.useState([]);

  const [rows, setRows] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);

  useEffect(() => {
    Auth.post("getVideoLog", {
      school_id: Auth.getProfile().school_id,
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        let items = [];
        if (res.status) {
          res.data.forEach((element) => {
            items.push(element);
            // console.log("item", element);
          });
          setRows(items);
          setTotalPages(Math.ceil(res.total_rec / limit));
        }
        console.log("res", res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [page, limit, searchText]);

  useEffect(() => {
    Auth.post("classes", {
      // school_id: 1,
    })
      .then((res) => {
        let items = [];
        if (res.status) {
          res.data.forEach((element) => {
            items.push({
              id: element.grade_id,
              name: element.class_name,
            });
          });
        }
        console.log("class", res);
        setGrades(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    Auth.post("sections", {
      // school_id: 1,
      grade_id: searchClass.id,
    })
      .then((res) => {
        let items = [];
        if (res.status)
          res.data.forEach((element) => {
            items.push({
              id: element.section_id,
              name: element.section_name,
            });
          });
        setSections(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [searchClass]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const getSectionList = (grade) => {
    console.log(grade);
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const Search = () => {
    Auth.post("getVideoLog", {
      searchText: searchText,
      searchClassName: searchClass.name,
      searchSectionName: searchSection.name,
    })
      .then((res) => {
        let items = [];
        if (res.status) {
          res.data.forEach((element) => {
            items.push(element);
            // console.log("item", element);
          });
          setRows(items);
          setTotalPages(Math.ceil(res.total_rec / limit));
        }
        console.log("res", res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <Button
        style={{ float: "right"}}
        variant="contained"
        color="primary"
        onClick={Search}
        size="large"
        endIcon={<SearchIcon />}
      >
        Search
      </Button>
      <TextField
        style={{ float: "right", width: "350px", marginRight: "5px" }}
        id="standard-adornment-password"
        type="text"
        label="Search By Student Name"
        variant="outlined"
        name="searchText"
        value={searchText}
        onChange={handleSearch}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton aria-label="Search By Student Name" onClick={Search}>
        //         <SearchIcon />
        //       </IconButton>
        //     </InputAdornment>
        //   ),
        // }}
      />

      {sections.length ? (
        <Autocomplete
          style={{ float: "right", width: "300px", marginRight: "5px" }}
          id="combo-box-demo-2"
          options={sections}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setSearchSection(newValue ? newValue : "");
          }}
          className={classes.paper}
          renderInput={(params) => (
            <TextField {...params} label="Section List" variant="outlined" />
          )}
        />
      ) : (
        ""
      )}

      {grades.length ? (
        <Autocomplete
          style={{ float: "right", width: "300px", marginRight: "5px" }}
          id="combo-box-demo-1"
          options={grades}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setSearchClass(newValue ? newValue : "");
            getSectionList(newValue);
          }}
          className={classes.paper}
          renderInput={(params) => (
            <TextField {...params} label="Search by Class" variant="outlined" />
          )}
        />
      ) : (
        ""
      )}

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
        <div style={{ float: "right", margin: "10px" }}>
          <Pagination
            page={page}
            count={totalPages}
            variant="outlined"
            color="primary"
            onChange={handleChange}
          />
        </div>
      </Paper>
    </div>
  );
}
