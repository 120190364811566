import React, { useState, useCallback } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
import { Button } from "@material-ui/core";
import AuthService from "../../auth/AuthService";
import List from "@material-ui/core/List";
import Message from "./../Message";

const Auth = new AuthService();
const style = {
  // width: 700,
};
export default function Containe({ videosList, itemDeleted, itemSoftDelete }) {
  const [cards, setCards] = useState(videosList);
  const [msg, setMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("");

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  const updateOrder = () => {
    let object = {};
    let index = 1;
    cards.forEach((element) => {
      object[element.id] = index++;
    });
    setMsg("");

    Auth.post("updateVideoOrder", {
      keys_object: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status) {
          setMsg("Video Order updated successfully");
          setMsgType("success");
          //   setUploading(false);
          //   props.blobItem(blobsInContainer, props.name);
        }
        console.log("res", res);
      })
      .catch((e) => {
        setMsg("Video Order not updated");
        setMsgType("error");
        console.error(e);
      });
  };
  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        // id={card.id}
        // text={card.video_link}
        videoItem={card}
        itemDeleted={itemDeleted}
        itemSoftDelete={itemSoftDelete}
        moveCard={moveCard}
      />
    );
  };
  return (
    <>
      <List style={style}>{cards.map((card, i) => renderCard(card, i))}</List>
      <Message msg={msg} type={msgType} />

      <Button
        onClick={() => {
          updateOrder();
        }}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </>
  );
}
