import React from "react";
import AuthService from "./components/auth/AuthService";
import { Redirect } from "react-router-dom";
import config from "./config";

const Auth = new AuthService();

class AdminRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const isAuthenticated = Auth.loggedIn();
    const app = config.app;

    return isAuthenticated && app === "Admin" ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: "/media" }} />
    );
  }
}

export default AdminRoute;
