import React, { useState, useCallback } from 'react';
import { TopicCard } from './TopicCard';
import update from 'immutability-helper';
import { Button } from '@material-ui/core';
import AuthService from '../../auth/AuthService';
import List from '@material-ui/core/List';

const Auth = new AuthService();
const style = {
  // width: 700,
};
export default function TopicContainer({ list, setList }) {
  const [cards, setCards] = useState(list);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  const updateOrder = () => {
    let object = {};
    let index = 1;
    cards.forEach((element) => {
      object[element.id] = index++;
    });
    Auth.post('updateTopicOrder', {
      keys_object: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status) {
          //   setUploading(false);
          //   props.blobItem(blobsInContainer, props.name);
        }
        console.log('res', res);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const renderCard = (card, index) => {
    return (
      <TopicCard
        key={card.id}
        index={index}
        handleItemEdit={(item) => {
          console.log('item', item);
          console.log('cards', cards);
          // TODO: Fix render
          setList({ ...cards, item });
          setCards(list);
        }}
        // id={card.id}
        // text={card.video_link}
        topicItem={card}
        // itemDeleted={itemDeleted}
        moveCard={moveCard}
      />
    );
  };
  return (
    <React.Fragment>
      <List style={style} key={cards.length}>
        {cards.map((card, i) => renderCard(card, i))}
      </List>
      <Button
        onClick={() => {
          updateOrder();
        }}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </React.Fragment>
  );
}
