
import config from '../../../config'
const accessKeyId = config.accessKeyId; // Replace with your AWS access key
const secretAccessKey = config.secretAccessKey; // Replace with your AWS secret key
const region = config.region; // Replace with your AWS region
const bucketName = config.bucketname; 


const s3 = {}
export const blobService = {
  getContainerClient: (containerName) => {
    return {
      containerName,
      createIfNotExists: async () => {
        return { containerName };
      },
      upload: async (file, folder) => {
        // Read the file content using FileReader
        const fileContent = await readFileContent(file);

        const params = {
          Bucket: bucketName,
          Key: `${folder}/${file.name}`,
          Body: fileContent,
          ContentType: file.type,
        };

        await s3.upload(params).promise();
      },
      listBlobsFlat: async () => {
        const params = {
          Bucket: bucketName,
          Prefix: containerName,
        };

        const data = await s3.listObjectsV2(params).promise();
        return data.Contents.map((item) => ({ name: item.Key }));
      },
    };
  },
};

const readFileContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const isStorageConfigured = () => {
  return !accessKeyId || !secretAccessKey || !region || !bucketName ? false : true;
};

export const getBlobInContainer = async (containerName, fileName) => {
  return `${containerName}/${fileName}`;
};

const createBlobInContainer = async (file, folder) => {
  const fileContent = await readFileContent(file);

  const params = {
    Bucket: bucketName,
    Key: `${folder}/${file.name}`,
    Body: fileContent,
    ContentType: file.type,
  };

  await s3.upload(params).promise();
};

export const getBlobsInContainer = async (containerClient, folder) => {
  const params = {
    Bucket: bucketName,
    Prefix: folder,
  };

  const data = await s3.listObjectsV2(params).promise();

  return data.Contents.map((item) => `https://${bucketName}.s3.${region}.amazonaws.com/${item.Key}`);
};

const uploadFileToBlob = async (file, folder) => {
  if (!file) return [];

  console.log('folder', folder);

  const containerClient = blobService.getContainerClient(folder);
  await containerClient.createIfNotExists();

  await createBlobInContainer(file, folder);

  return getBlobInContainer(folder, file.name);
};

export default uploadFileToBlob;
