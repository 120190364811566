import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core/";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthService from "../auth/AuthService";
import districts from "../../constants/districts";
import Message from "../common/Message";
export default class AddSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      status: 1,
      province: "",
      googleCoordinates: "",
      region: "",
      open: false,
      schoolModel: "",
      startDate: "",
      village: "",
      council: "",
      tehsil: "",
      district: "",
      electricity: "",
      internetAvailable: "",
      distanceNearestSchool: "",
      schoolType: "",
      estimatedHousehold: "",
      spaceType: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }
  // const[open, setOpen] = React.useState (false);

  handleClickOpen() {
    this.resetForm();
    this.setState({ open: true });
  }

  resetForm() {
    this.setState({
      name: "",
      address: "",
      status: 1,
      province: "",
      latitude: "",
      longitude: "",
      open: false,
      errorMessage: "",
      nameErrorMessage: "",
      addressErrorMessage: "",
      latitudeErrorMessage: "",
      longitudeErrorMessage: "",
      serverError: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState(
      {
        nameErrorMessage: this.state.name === "" ? "Name is required " : "",
        addressErrorMessage:
          this.state.address === "" ? "Address Name is required " : "",
        latitudeErrorMessage:
          this.state.latitude === "" ? "Latitude is required" : "",
        longitudeErrorMessage:
          this.state.longitude === "" ? "Longitude is required" : "",
      },
      () => {
        if (
          this.state.nameErrorMessage.length > 0 ||
          this.state.addressErrorMessage.length > 0
          // this.state.latitudeErrorMessage.length > 0 ||
          // this.state.longitudeErrorMessage.length > 0
        ) {
          this.setState({
            serverError: "Invalid data provided",
          });
          console.log("Invalid data provided");
        } else {
          this.Auth.post("crud", {
            action: "create",
            table: "schools",
            name: this.state.name,
            address: this.state.address,
            latitude: this.state.district,
            longitude: this.state.googleCoordinates,
            status: this.state.status,
            offline: this.state.offline,
            region: this.state.region,
            province: this.state.province,
            schoolModel: this.state.schoolModel,
            startDate: this.state.startDate,
            village: this.state.village,
            council: this.state.council,
            tehsil: this.state.tehsil,
            electricity: this.state.electricity,
            internetAvailable: this.state.internetAvailable,
            distanceNearestSchool: this.state.distanceNearestSchool,
            schoolType: this.state.schoolType,
            estimatedHousehold: this.state.estimatedHousehold,
            spaceType: this.state.spaceType,
          })
            .then((data) => {
              console.log(data);
              if (data.error) {
                this.setState({ serverError: data.message });
              } else {
                this.resetForm();
                // this.props.propAddLink(data.user);
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.setState({
                serverError: "some error occurred please try again",
              });
            });
        }
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { plus } = this.props;
    return (
      <div>
        {plus ? (
          <Tooltip title="Add School">
            <AddCircleIcon
              style={{
                cursor: "pointer",
                fontSize: 30,
                float: "right",
                color: "#FF6162",
              }}
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            />
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Open form dialog
          </Button>
        )}
        <br style={{ clear: "both" }} />

        <form onSubmit={this.handleSubmit}>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Create School</DialogTitle>
            {/* {this.state.serverError === "" ? (
              ""
            ) : (
                <div className="alert alert-danger" role="alert">
                  {this.state.serverError}
                </div>
              )} */}
            <Message msg={this.state.serverError} type="error" />
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="School Name"
                type="text"
                value={this.state.name || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.nameErrorMessage === "" ? false : true}
                helperText={this.state.nameErrorMessage}
              />
              <TextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                value={this.state.address || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.addressErrorMessage === "" ? false : true}
                helperText={this.state.addressErrorMessage}
              />
              <TextField
                margin="dense"
                id="googleCoordinates"
                name="googleCoordinates"
                label="Google Coordinates"
                type="text"
                value={this.state.googleCoordinates || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.addressErrorMessage === "" ? false : true}
                // helperText={this.state.addressErrorMessage}
              />

              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Offline</InputLabel>
                <Select
                  id="offline"
                  name="offline"
                  type="text"
                  value={this.state.offline || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="0">Online</MenuItem>
                  <MenuItem value="1">Offline</MenuItem>
                </Select>
              </FormControl> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  id="region"
                  name="region"
                  type="text"
                  value={this.state.region || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="urban">Urban</MenuItem>
                  <MenuItem value="rural">Rural</MenuItem>
                  <MenuItem value="semi">Semi-Urban</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  School Model
                </InputLabel>
                <Select
                  id="offline"
                  name="offline"
                  type="text"
                  value={this.state.offline || ""}
                  // id="schoolModel"
                  // name="schoolModel"
                  // type="text"
                  // value={this.state.schoolModel || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Offline">Offline</MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                id="startDate"
                name="startDate"
                label="Start Date"
                type="date"
                value={this.state.startDate || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.addressErrorMessage === "" ? false : true}
                // helperText={this.state.startDate}
              />
              <TextField
                margin="dense"
                id="village"
                name="village"
                label="Village"
                type="text"
                value={this.state.village || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.villageErrorMessage === "" ? false : true}
                // helperText={this.state.villageErrorMessage}
              />
              <TextField
                margin="dense"
                id="council"
                name="council"
                label="Union Council"
                type="text"
                value={this.state.council || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.councilErrorMessage === "" ? false : true}
                // helperText={this.state.councilErrorMessage}
              />
              <TextField
                margin="dense"
                id="tehsil"
                name="tehsil"
                label="Tehsil"
                type="text"
                value={this.state.tehsil || ""}
                fullWidth
                onChange={this.handleChange}
                // error={this.state.tehsilErrorMessage === "" ? false : true}
                // helperText={this.state.tehsilErrorMessage}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">District</InputLabel>
                <Select
                  id="district"
                  name="district"
                  type="text"
                  value={this.state.district || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  {districts.map((d) => {
                    return <MenuItem value={d}>{d}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Province</InputLabel>
                <Select
                  id="province"
                  name="province"
                  label="Province"
                  type="text"
                  value={this.state.province || ""}
                  fullWidth
                  onChange={this.handleChange}
                >
                  <MenuItem value="Punjab">Punjab</MenuItem>
                  <MenuItem value="Sindh">Sindh</MenuItem>
                  <MenuItem value="Balochistan">Balochistan</MenuItem>
                  <MenuItem value="KPK">Khyber Pakhtunkhwa</MenuItem>
                  <MenuItem value="Gilgit">Gilgit Baltistan</MenuItem>
                  <MenuItem value="AJK">AJK</MenuItem>
                  <MenuItem value="ICT">ICT</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                margin="dense"
                id="latitude"
                name="latitude"
                label="Latitude"
                type="string"
                value={this.state.latitude || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.latitudeErrorMessage === "" ? false : true}
                helperText={this.state.latitudeErrorMessage}
              /> */}

              {/* <TextField
                margin="dense"
                id="longitude"
                name="longitude"
                label="Latitude"
                type="string"
                value={this.state.longitude || ""}
                fullWidth
                onChange={this.handleChange}
                error={this.state.longitudeErrorMessage === "" ? false : true}
                helperText={this.state.longitudeErrorMessage}
              /> */}
              {/* <TextField
                id="province"
                name="province"
                label="Province"
                select
                value={this.state.province}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="select" value=" ">
                  Select Province
                </option>
                <option key="AJK" value="AJK">
                  AJK
                </option>
                <option key="Balochistan" value="Balochistan">
                  Balochistan
                </option>
                <option key="GB" value="GB">
                  GB
                </option>
                <option key="Islamabad" value="Islamabad">
                  Islamabad
                </option>
                <option key="KPK" value="KPK">
                  KPK
                </option>
                <option key="Punjab" value="Punjab">
                  Punjab
                </option>
                <option key="Sindh" value="Sindh">
                  Sindh
                </option>
              </TextField> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Electricity Available
                </InputLabel>
                <Select
                  id="electricity"
                  name="electricity"
                  type="text"
                  value={this.state.electricity || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Internet Available?
                </InputLabel>
                <Select
                  id="internetAvailable"
                  name="internetAvailable"
                  type="text"
                  value={this.state.internetAvailable || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Distance from Nearest accessible school (Km)
                </InputLabel>
                <Select
                  id="distanceNearestSchool"
                  name="distanceNearestSchool"
                  type="text"
                  value={this.state.distanceNearestSchool || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4-5">4-5</MenuItem>
                  <MenuItem value="More than 5">More than 5</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Type of Nearest School :
                </InputLabel>
                <Select
                  id="schoolType"
                  name="schoolType"
                  type="text"
                  value={this.state.schoolType || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="Government School">
                    Government School
                  </MenuItem>
                  <MenuItem value="Private School">Private School</MenuItem>
                  <MenuItem value="Non-Formal School">
                    Non-Formal School
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Estimated Number of Household in the area:
                </InputLabel>
                <Select
                  id="estimatedHousehold"
                  name="estimatedHousehold"
                  type="text"
                  value={this.state.estimatedHousehold || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="30">30</MenuItem>
                  <MenuItem value="50-60">50-60</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="More than 100">More than 100</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Type of Space for School:
                </InputLabel>
                <Select
                  id="spaceType"
                  name="spaceType"
                  type="text"
                  value={this.state.spaceType || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="Teacher House">Teacher House</MenuItem>
                  <MenuItem value="Community Space">
                    Community Space i.e Baithak
                  </MenuItem>
                  <MenuItem value="Rented Facility">Rented Facility</MenuItem>
                  <MenuItem value="Outdoor">Outdoor</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Current Status of School:
                </InputLabel>
                <Select
                  id="status"
                  name="status"
                  type="text"
                  value={this.state.status || ""}
                  onChange={this.handleChange}
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">InActive</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                id="status"
                name="status"
                label="Status"
                select
                value={this.state.status}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                // helperText={this.state.errorMessage}
                // error={this.state.errorMessage === "" ? false : true}
              >
                <option key="Active" value="1">
                  Active
                </option>
                <option key="Inactive" value="2">
                  Inactive
                </option>
              </TextField> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ errorMessage: "", open: false });
                  this.resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                onClick={this.handleSubmit}
                color="primary"
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}
