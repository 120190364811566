import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import AuthService from "../../auth/AuthService";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

const Auth = new AuthService();

const CARD = "card";
export const TopicCard = ({
  id,
  index,
  moveCard,
  topicItem,
  handleItemEdit,
}) => {
  const ref = useRef(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [updatedTopicName, setUpdatedTopicName] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);

  const [, drop] = useDrop({
    accept: CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const handleClickOpen = (topic) => {
    setSelectedTopic(topic);
    setUpdatedTopicName(topic?.name);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    setModalError(null);
    setProgress(true);
    Auth.post("crud", {
      id_key: "id",
      id_value: selectedTopic.id,
      table: "topics",
      action: "update",
      name: updatedTopicName,
    })
      .then((res) => {
        if (res.status) {
          const topic = topicItem;
          topic.name = updatedTopicName;
          setOpen(false);
          setProgress(false);
          return;
        }
        throw new Error(res.error);
      })
      .catch((e) => {
        console.log(e);
        setProgress(false);
        setModalError(e);
      });
  };
  const [{ isDragging }, drag] = useDrag({
    item: { type: CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleTopicStatus = () => {
    console.log("handleing topic");
    console.log(topicItem);
    let status = 1;
    if (parseInt(topicItem.status) === 1) {
      status = 0;
    }
    // topicItem.status = status;
    handleItemEdit({ topicItem, status });
    Auth.post("crud", {
      action: "update",
      table: "topics",
      id_key: "id",
      id_value: topicItem.id,
      status,
    });
  };

  const getStatus = () => {
    console.log("topicItem", topicItem.status);
    if (topicItem.status === "1" || topicItem.status === null) return true;
    return false;
  };
  return (
    <div>
      <ListItem
        ref={ref}
        style={{ opacity }}
        secondaryAction={
          <Box>
            <IconButton edge="end" aria-label="delete">
              <Switch
                checked={getStatus()}
                onChange={handleTopicStatus}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </IconButton>
            <IconButton edge="end" aria-label="delete">
              <EditIcon onClick={() => handleClickOpen(topicItem)} />
            </IconButton>
          </Box>
        }
      >
        <ListItemText
          primary={topicItem.name}
          secondary={"Topic Order: " + topicItem.topic_order}
        />
      </ListItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Topic</DialogTitle>
        <DialogContent>
          <Grid container justify="space-between">
            <Grid item mb={5} xs={12}>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Update Topic Name"
                value={updatedTopicName}
                onChange={(e) => setUpdatedTopicName(e.target.value)}
                fullWidth
                error={modalError}
              />
            </Grid>
          </Grid>

          {modalError ? (
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color={"danger"}
              style={{
                color: "red",
              }}
            >
              Error Occured, try again
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {progress ? (
            <Box sx={{ width: "100%" }} justifyItems="center">
              <LinearProgress />
            </Box>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Update
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );

  // <ListItem
  //   ref={ref}
  //   style={{ opacity }}
  //   secondaryAction={
  //     <IconButton edge="end" aria-label="delete">
  //       <DeleteIcon />
  //     </IconButton>
  //   }
  // >
  //   <ListItemText
  //     primary={topicItem.name}
  //     secondary={"Topic Order: " + topicItem.topic_order}
  //   />

  //   {/* <ListItem>
  //     <ListItemAvatar>
  //       <Avatar></Avatar>
  //     </ListItemAvatar>
  //     <ListItemText
  //       primary="Single-line item"
  //       // secondary="{" ? "Secondary text" : null}
  //     />
  //   </ListItem> */}
  // </ListItem>
  // );
  // return (<div ref={ref} style={{ ...style, opacity }}>
  // 		{text}
  // 	</div>);
};
