import React from "react";
import { Grid } from "@material-ui/core";
import VideoDropDown from "./common/VideoDropDown";
import VideoUpload from "./common/lib/VideoUpload";
import LevelStatus from "./common/LevelStatus";
export default function Main() {
  const [selection, setSelection] = React.useState([]);
  return (
    <>
      {/* <VideoUpload blobContainer={[1, 1, 1, 1]} mainContainer="videos" /> */}

      <Grid item xs={12}>
        <h1>Video Uploader</h1>
      </Grid>
      <VideoDropDown selection={setSelection} />

      <Grid container item spacing={5}>
        <Grid item xs>
          {selection[0] && selection[1] && selection[2] && selection[3] ? (
            <VideoUpload blobContainer={selection} mainContainer="videos" />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={3}>
          {selection[0] && selection[1] ? (
            <>
              <h3>Present Levels</h3>
              <LevelStatus
                grade={selection[0]}
                subject={selection[1]}
                level={selection[2]}
              />{" "}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
}
