import React, { useEffect, useState } from "react";
import AuthService from "../auth/AuthService";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/Assessment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
// import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import Label from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const limitText = (text) => {
  if (text.length > 50) {
    text = text.substring(0, 49) + "...";
  }
  return text;
};

const useTreeItemStyles = makeStyles((theme) => ({
  // root: {
  //   color: theme.palette.text.secondary,
  //   "&:hover > $content": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   "&:focus > $content, &$selected > $content": {
  //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
  //     color: "var(--tree-view-color)",
  //   },
  //   "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
  //     backgroundColor: "transparent",
  //   },
  // },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    item,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {limitText(labelText)} <br /> <i>{item.topic_name}</i>
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  select: {
    backgroundColor: "#bcbcbc",
  },
  // pass: { backgroundColor: "#3BB143" },
}));

export default function LevelStatus({ grade, subject, level }) {
  const classes = useStyles();
  const [videosList, setVideosList] = useState([]);
  const [nodeIds, setNoodIds] = useState([]);

  // const handleChange = (video) => {
  //   console.log(video);
  // };

  useEffect(() => {
    const Auth = new AuthService();
    if (grade && subject) {
      Auth.post("getRunningVideo", {
        // school_id: 1,
        grade_id: grade,
        section_id: 1,
        subject_id: subject,
        // start_date: "2020-12-06", // @TODO Attendance
      })
        .then((res) => {
          if (res.status) {
            let i = 0;
            let nodeIds = [];
            let data = res.data;
            // if (level) {
            //   data = data.filter((x) => x.level_id === level);
            // }
            let result = data.reduce(function (r, a) {
              let key = JSON.stringify({ id: a.level_id, value: a.level_name }); //`${a.level_id} - ${a.level_name}`;
              r[key] = r[key] || [];
              r[key].push(a);
              nodeIds.push(i++ + "");
              return r;
            }, Object.create(null));

            setNoodIds(nodeIds);
            setVideosList(result);

            // console.log("nodeIds", nodeIds);
          } else {
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [grade, subject, level]);

  return nodeIds.length > 0 ? (
    <TreeView
      className={classes.root}
      defaultExpanded={nodeIds}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {Object.keys(videosList).map((item, objectIndex) => {
        return (
          <StyledTreeItem
            key={objectIndex}
            nodeId={objectIndex + ""}
            labelText={JSON.parse(item).value}
            labelIcon={Label}
            item={item}
          >
            {videosList[item].map((item, index) => {
              return (
                <StyledTreeItem
                  item={item}
                  // className={item.pass === "1" ? classes.pass : ""}
                  key={index + 1}
                  nodeId="child"
                  labelText={
                    item.status === "1"
                      ? item.video_link.split("/")[2]
                      : `${item.assessment_name}`
                  }
                  labelIcon={
                    item.status === "2" ? AssessmentIcon : VideoLibraryIcon
                  }
                  labelInfo={
                    item.status === "2"
                      ? item.result_count
                      : item.result_count_logs
                  }
                  color="#1a73e8"
                  bgColor="#e8f0fe"
                />
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  ) : (
    "No Videos available for selected level"
  );
}
