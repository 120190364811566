import React, { useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles } from "@material-ui/core/styles";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import FolderIcon from "@material-ui/icons/Folder";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import SyncIcon from "@material-ui/icons/Sync";
// import SyncProblemRoundedIcon from "@material-ui/icons/SyncProblemRounded";
// import LinearProgress from "@material-ui/core/LinearProgress";
import StopIcon from "@material-ui/icons/Stop";
// import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import Box from "@material-ui/core/Box";
import AuthService from "../../../auth/AuthService";
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  downlaoding: {
    backgroundColor: "#3f51b5",
  },
  notSynced: {
    backgroundColor: "#C6B9CD",
  },
  cursor: { cursor: "pointer" },
}));
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
export default function Main({ videoItem }) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  const generateString = (item) => {
    if(item.grade_name !== undefined){
      return `${item.grade_name} > ${item.subject_name} > ${item.level_name} > ${item.topic_name} >`
    }else {
      return "Video: ";
    }
  }

  useEffect(() => {
    let percent = 0;
    if (videoItem.status === "1" && videoItem.total_size !== "0") {
      percent = (videoItem.downloaded_size / videoItem.total_size) * 100;
    }
    setProgress(percent);
  }, [videoItem]);
  const mbConvert = (sizeInBytes) => {
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return sizeInMB + "MB";
  };
  const videoVersion = (videoItem) => {
    let differene = videoItem.video_version - videoItem.sync_version;
    let message = "";
    if (differene) {
      message = `This Video is ${differene} version(s) behind`;
    }
    return message;
  };

  const videoName = (name) => {
    return name.split("/").pop();
  };
  const selectClass = (videoItem) => {
    let differene = videoItem.video_version - videoItem.sync_version;
    if (differene) {
      return classes.notSynced;
    }
  };
  return (
    <ListItem className={selectClass(videoItem)}>
      <ListItemAvatar>
        <Avatar>
          <VideoLibraryIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={`${generateString(videoItem)} ${videoName(videoItem.video_link)} (${mbConvert(
          videoItem.total_size
        )})`}
        secondary={`${videoItem.video_link}, ${videoVersion(videoItem)}`}
      />

      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="sync">
          <CircularProgressWithLabel value={progress} />
          {/* <SyncProblemRoundedIcon /> */}
        </IconButton>
        {videoItem.downloading === "0" ? (
          <IconButton
            onClick={() => {
              console.log("videoItem", videoItem);
              Auth.post("download-video", {
                id: videoItem.id,
              })
                .then((res) => {
                  console.log("res", res);
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
            edge="end"
            aria-label="sync"
          >
            <SyncIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              console.log("videoItem", videoItem);
              Auth.post("kill-video-sync", {
                id: videoItem.id,
              })
                .then((res) => {
                  console.log("res", res);
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
            edge="end"
            aria-label="sync"
          >
            <StopIcon />
          </IconButton>
        )}
        {videoItem.downloading === "0" ? (
          <IconButton
            onClick={() => {
              console.log("videoItem", videoItem);
              Auth.post("delete-local-video", {
                id: videoItem.id,
              })
                .then((res) => {
                  console.log("res", res);
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
            edge="end"
            aria-label="sync"
          >
            <HighlightOffIcon style={{ color: red[500] }} />
          </IconButton>
        ) : (
          ""
        )}

        {/* {videoItem.downloading !== "0" ? (
          <IconButton
            onClick={() => {
              console.log("videoItem", videoItem);
              Auth.post("kill-video-sync", {
                id: videoItem.id,
              })
                .then((res) => {
                  console.log("res", res);
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
            edge="end"
            aria-label="sync"
          >
            <StopIcon />
          </IconButton>
        ) : (
          ""
        )} */}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
