import React, { useEffect, useContext, useState } from "react";
import ThingsContext from "../../Layout/thingsContext";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import AuthService from "../../auth/AuthService";
import AssessmentIcon from "@material-ui/icons/Assessment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
// import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import Label from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const Auth = new AuthService();

const limitText = (text) => {
  if (text.length > 23) {
    text = text.substring(0, 20) + "...";
  }
  return text;
};

const useTreeItemStyles = makeStyles((theme) => ({
  // root: {
  //   color: theme.palette.text.secondary,
  //   "&:hover > $content": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   "&:focus > $content, &$selected > $content": {
  //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
  //     color: "var(--tree-view-color)",
  //   },
  //   "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
  //     backgroundColor: "transparent",
  //   },
  // },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    item,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {item.pass !== undefined && (
            <Checkbox
              edge="end"
              readOnly={true}
              // onChange={handleChange(item)}
              // className={item.done === "0" ? classes.select : ""}
              checked={item.pass === "1" ? true : false}
              // checked={checked.indexOf(item) !== -1}
              // inputProps={{ "aria-labelledby": labelId }}
            />
          )}

          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {limitText(labelText)} <br /> <i>{item.topic_name}</i>
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

// StyledTreeItem.propTypes = {
//   bgColor: PropTypes.string,
//   color: PropTypes.string,
//   labelIcon: PropTypes.elementType.isRequired,
//   labelInfo: PropTypes.string,
//   labelText: PropTypes.string.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  select: {
    backgroundColor: "#bcbcbc",
  },
  // pass: { backgroundColor: "#3BB143" },
}));

export default function FormPropsTextFields(props) {
  const classes = useStyles();
  const [things, setThings] = useContext(ThingsContext);
  const [videosList, setVideosList] = useState([]);
  const [nodeIds, setNoodIds] = useState([]);

  // const handleChange = (video) => {
  //   console.log(video);
  // };

  const handleAssessmentClick = (item) => {
    console.log('item', item)
    if (item.status === "1" && item.done >= "1") {
      // console.log("inside click 123");
      // console.log("item", item);
      let temp = things;
      temp[3] = item;
      setThings([...temp]);
      // console.log("things", things);
    }
  };

  useEffect(() => {
    if (things[0] && things[1] && things[2]) {
      Auth.post("getRunningVideo", {
        // school_id: 1,
        grade_id: things[0],
        section_id: things[1],
        subject_id: things[2],
        // start_date: "2020-12-06", // @TODO Attendance
      })
        .then((res) => {
          if (res.status) {
            console.log("res.data", res.data);
            let i = 0;
            let nodeIds = [];
            let result = res.data.reduce(function (r, a) {
              let key = JSON.stringify({ id: a.level_id, value: a.level_name }); //`${a.level_id} - ${a.level_name}`;
              r[key] = r[key] || [];
              r[key].push(a);
              nodeIds.push(i++ + "");
              return r;
            }, Object.create(null));
            // console.log("result", result);
            // setAllKeys(Object.keys(result))
            // for (const [key, value] of Object.entries(result)) {
            //   console.log(';resultresult', `${key}: ${JSON.stringify(value)}`);
            // }
            console.log("nodeIds", nodeIds);
            setNoodIds(nodeIds);
            setVideosList(result);
          } else {
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [things]);

  return nodeIds.length > 0 ? (
    <TreeView
      className={classes.root}
      defaultExpanded={nodeIds}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {Object.keys(videosList).map((item, objectIndex) => {
        return (
          <StyledTreeItem
            key={objectIndex}
            nodeId={objectIndex + ""}
            labelText={JSON.parse(item).value}
            labelIcon={Label}
            item={item}
          >
            {videosList[item].map((item, index) => {
              return (
                <StyledTreeItem
                  item={item}
                  // className={item.pass === "1" ? classes.pass : ""}
                  key={index + 1}
                  nodeId="child"
                  labelText={
                    item.status === "1"
                      ? item.video_link.split("/")[2]
                      : `${item.assessment_name}`
                  }
                  labelIcon={
                    item.status === "2" ? AssessmentIcon : VideoLibraryIcon
                  }
                  labelInfo={
                    item.status === "2"
                      ? item.result_count
                      : item.result_count_logs
                  }
                  color="#1a73e8"
                  bgColor="#e8f0fe"
                  onClick={() => {
                    handleAssessmentClick(item);
                  }}
                />
              );
            })}
          </StyledTreeItem>
        );
      })}
    </TreeView>
  ) : (
    "No subject selected"
  );
}
