// import decode from 'jwt-decode';
// import jwt from 'jsonwebtoken';
import config from "./../../config";
import decode from "jwt-decode";

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || config.domain; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
  }

  login(username, password) {
    return this.fetch("loginStudents", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams({
        role: "Teacher",
        password: password,
        username: username,
      }),
    })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          this.setToken(res.data.token);
          return Promise.resolve(res);
        } else {
          return Promise.reject(res);
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });

    // // Get a token from api server using the fetch api
    // return this.fetch(`${this.domain}/login`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     username,
    //     password,
    //   }),
    // }).then((res) => {
    //   this.setToken(res.token); // Setting the token in localStorage
    //   this.setUserEmail(res.email); // Setting the token in localStorage
    //   this.setAdminType(res.role);
    //   return Promise.resolve(res);
    // });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    // console.log('token', token)
    return !!token; // handwaiving here
  }

  getAdminType() {
    // Retrieves the admin type from localStorage
    if(this.loggedIn()) return this.getProfile().role === "1";
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.setItem("drawer_open", 0);
    localStorage.setItem("drawer_open_right", 0);
    window.location.replace("/");
  }

  // test(props) {
  //   props.history.replace(config.app==="Studio" ? "/media" : "/question");
  // }

  fetch(url, options) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch(`${this.domain}${url}`, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  post(url, body) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    if (this.loggedIn()) {
      headers["x-api-key"] = this.getToken();
    }
    return fetch(`${this.domain}${url}`, {
      headers,
      method: "POST",
      body: new URLSearchParams(body),
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  _checkStatus(response) {
    // console.log('response', response)
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status <= 500) {
      // Success status lies between 200 to 300
      return response;
    } else {
      console.log('ERROR:: ', response)
      if (response.status === 400) {
        return response;
      }
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
    localStorage.setItem("drawer_open", 0);
    localStorage.setItem("drawer_open_right", 0);

  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }
  getProfile() {
    // Using jwt-decode npm package to decode the token
    if(this.loggedIn()) return decode(this.getToken());
  }
}
