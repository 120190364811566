import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AuthService from '../auth/AuthService';
import UpdateIcon from '@material-ui/icons/Update';
import Message from '../common/Message';
import { MenuItem, Select } from '@material-ui/core';

export default class EditSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      address: props.address,
      status: props.status,
      province: props.province,
      latitude: props.latitude,
      longitude: props.longitude,
      open: false,
      errorMessage: '',
      nameErrorMessage: '',
      addressErrorMessage: '',
      statusErrorMessage: '',
      latitudeErrorMessage: '',
      longitudeErrorMessage: '',
      serverError: '',
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }
  // const[open, setOpen] = React.useState (false);

  handleClickOpen() {
    this.setState({ open: true });
  }

  resetForm() {
    this.setState({
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      status: 1,
      offline: 0,
      province: '',
      open: false,
      errorMessage: '',
      nameErrorMessage: '',
      addressErrorMessage: '',
      latitudeErrorMessage: '',
      longitudeErrorMessage: '',
      statusErrorMessage: '',
      serverError: '',
    });
  }

  handleSubmit(event) {
    this.setState(
      {
        nameErrorMessage:
          this.state.first_name === '' ? 'Name is required ' : '',
        addressErrorMessage:
          this.state.last_name === '' ? 'Address Name is required ' : '',
        latitudeErrorMessage:
          this.state.latitude === '' ? 'Latitude is required' : '',
        longitudeErrorMessage:
          this.state.longitude === '' ? 'Longitude is required' : '',
      },
      () => {
        if (
          this.state.nameErrorMessage.length > 0 ||
          this.state.addressErrorMessage.length > 0 ||
          this.state.latitudeErrorMessage.length > 0 ||
          this.state.longitudeErrorMessage.length > 0
        ) {
          // this.setState({
          //   serverError: "Invalid data provided",
          // });
          console.log('Invalid data provided');
        } else {
          this.Auth.post('crud', {
            id_key: 'id',
            id_value: this.state.id,
            table: 'schools',
            action: 'update',
            name: this.state.name,
            address: this.state.address,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            status: this.state.status,
            offline: this.state.offline,
            province: this.state.province,
            region: this.state.region,
          })
            .then((data) => {
              console.log(data);
              if (data.error) {
                this.setState({ serverError: data.message });
              } else {
                this.resetForm();
                // this.props.propAddLink(data.user);
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log('err', err);
              this.setState({
                errorMessage: 'some error occurred please try again',
              });
            });
        }
      }
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { plus } = this.props;
    return (
      <div style={{ float: 'right' }}>
        {plus ? (
          <Tooltip title="Edit">
            <UpdateIcon
              style={{ cursor: 'pointer' }}
              variant="outlined"
              onClick={this.handleClickOpen}
            />
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickOpen}
          >
            Open form dialog
          </Button>
        )}
        <br style={{ clear: 'both' }} />

        <form onSubmit={this.handleSubmit}>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Update School</DialogTitle>
            {/* {this.state.serverError === "" ? (
              ""
            ) : (
                <div className="alert alert-danger" role="alert">
                  {this.state.serverError}
                </div>
              )} */}
            <Message msg={this.state.serverError} type="error" />
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                value={this.state.name || ''}
                fullWidth
                onChange={this.handleChange}
                error={this.state.nameErrorMessage === '' ? false : true}
                helperText={this.state.nameErrorMessage}
              />
              <TextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                value={this.state.address || ''}
                fullWidth
                onChange={this.handleChange}
                error={this.state.addressErrorMessage === '' ? false : true}
                helperText={this.state.addressErrorMessage}
              />
              <TextField
                margin="dense"
                id="latitude"
                name="latitude"
                label="Latitude"
                type="string"
                value={this.state.latitude || ''}
                fullWidth
                onChange={this.handleChange}
                error={this.state.latitudeErrorMessage === '' ? false : true}
                helperText={this.state.latitudeErrorMessage}
              />

              <TextField
                margin="dense"
                id="longitude"
                name="longitude"
                label="Latitude"
                type="string"
                value={this.state.longitude || ''}
                fullWidth
                onChange={this.handleChange}
                error={this.state.longitudeErrorMessage === '' ? false : true}
                helperText={this.state.longitudeErrorMessage}
              />

              <TextField
                id="province"
                name="province"
                label="Province"
                select
                value={this.state.province}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option key="select" value=" ">
                  Select Province
                </option>
                <option key="AJK" value="AJK">
                  AJK
                </option>
                <option key="Balochistan" value="Balochistan">
                  Balochistan
                </option>
                <option key="GB" value="GB">
                  GB
                </option>
                <option key="Islamabad" value="Islamabad">
                  Islamabad
                </option>
                <option key="KPK" value="KPK">
                  KPK
                </option>
                <option key="Punjab" value="Punjab">
                  Punjab
                </option>
                <option key="Sindh" value="Sindh">
                  Sindh
                </option>
              </TextField>
              <TextField
                id="status"
                name="status"
                label="Status"
                select
                value={this.state.status}
                onChange={this.handleChange}
                SelectProps={{
                  native: true,
                }}
                // helperText={this.state.errorMessage}
                // error={this.state.errorMessage === "" ? false : true}
              >
                <option key="Active" value="1">
                  Active
                </option>
                <option key="Inactive" value="2">
                  Inactive
                </option>
              </TextField>
              <Select
                id="offline"
                name="offline"
                type="text"
                value={this.state.offline || ''}
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value="0">Online</MenuItem>
                <MenuItem value="1">Offline</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ errorMessage: '', open: false });
                  this.resetForm();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
    );
  }
}
