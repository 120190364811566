import React, { useState, useCallback } from "react";
import QuestionsCountDropDown from "./QuestionsCountDropDown";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthService from "../auth/AuthService";

const Auth = new AuthService();

const QuestionCount = () => {
  //   const [selection, setSelection] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [open, setOpen] = useState(false);
  const [assessmentObject, setAssessmentObject] = useState({});
  const [questionCount, setQuestionCount] = useState(0);

  const handleClickOpen = (assessment) => {
    setQuestionCount(assessment.question_count);
    setAssessmentObject(assessment);
    setOpen(true);
  };

  const handleUpdate = () => {
    Auth.post("crud", {
      id_key: "assessment_id",
      id_value: assessmentObject.assessment_id,
      table: "assessments",
      action: "update",
      question_count: questionCount,
    })
      .then((res) => {
        const data = assessments.map((assessment) => {
          if (assessment.assessment_id === assessmentObject.assessment_id) {
            assessment.question_count = questionCount;
            return assessment;
          }
          return assessment;
        });
        setAssessments(data);
        setOpen(false);
      })
      .catch((e) => console.log(e));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const callAssessments = useCallback((data) => {
    setAssessments(data);
    console.log(data);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <h1>Question Count</h1>
      </Grid>
      <QuestionsCountDropDown callAssessments={callAssessments} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <List component="nav">
              {assessments.map((assessment) => {
                return (
                  <ListItem
                    key={assessment.assessment_id}
                    button
                    onClick={() => handleClickOpen(assessment)}
                  >
                    <div style={{ display: "flex" }}>
                      <h4>{assessment.assessment_name} </h4>
                      <sup style={{ color: "red" }}>
                        {" "}
                        {assessment.question_count} Questions
                      </sup>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Grid>
      </Grid>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {assessmentObject.assessment_name}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="count"
              label="Update Question Count"
              value={questionCount}
              onChange={(e) => setQuestionCount(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default QuestionCount;
