import React from "react";
import { Grid } from "@material-ui/core";

// import AuthService from "../components/auth/AuthService";

import VideoDropDown from "./common/VideoDropDown";
import VideoSync from "./common/lib/VideoSync";

// const Auth = new AuthService();

export default function Main(props) {
  const [selection, setSelection] = React.useState([]);
  return (
    <>
      {/* <VideoSync blobContainer={[1, 1, 1, 1]} mainContainer="videos" /> */}

      <Grid item xs={12}>
        <h1>Videos Syncing</h1>
      </Grid>
      <VideoDropDown selection={setSelection} />
      {selection[0] && selection[1] && selection[2] && selection[3] ? (
        <VideoSync blobContainer={selection} mainContainer="videos" />
      ) : (
        ""
      )}
    </>
  );
}
