import React from "react";
import { Grid } from "@material-ui/core";

// import AuthService from "../components/auth/AuthService";

import TopicDropDown from "../common/TopicDropDown";
import TopicContainer from "../common/lib/TopicContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// const Auth = new AuthService();

export default function Main(props) {
  // const [selection, setSelection] = React.useState([]);
  const [topicsList, setTopicsList] = React.useState([]);
  const topicProps = (param) => {
    console.log("topicProps", param);
    setTopicsList([]);
    setTopicsList(param);
  };
  return (
    <>
      <Grid item xs={12}>
        <h1>Topic Sorting</h1>
      </Grid>
      <TopicDropDown topicProps={topicProps} />
      <Grid item xs={12}>
        <DndProvider backend={HTML5Backend}>
          {topicsList.length ? (
            <TopicContainer list={topicsList} setList={setTopicsList} />
          ) : (
            ""
          )}
        </DndProvider>
      </Grid>
      {/* ) : (
        ""
      )} */}
    </>
  );
}
