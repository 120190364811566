import React, { useState, useEffect } from "react";

import AuthService from "../auth/AuthService";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const Auth = new AuthService();

const Manage = () => {
  const [assessments, setAssessments] = useState([]);
  const [assessmentObject, setAssessmentObject] = useState({});
  const [open, setOpen] = useState(false);
  const [newTitle, setnewTitle] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    Auth.post("crud", {
      id_key: "assessment_id",
      id_value: assessmentObject.assessment_id,
      table: "assessments",
      action: "update",
      assessment_name: newTitle,
    })
      .then((res) => {
        const data = assessments.map((assessment) => {
          if (assessment.assessment_id === assessmentObject.assessment_id) {
            assessment.assessment_name = newTitle;
            return assessment;
          }
          return assessment;
        });
        setAssessments(data);
        setOpen(false);
      })
      .catch((e) => console.log(e));
  };

  const handleClickOpen = (assessment) => {
    setnewTitle(assessment.assessment_name);
    setAssessmentObject(assessment);
    setOpen(true);
  };

  useEffect(() => {
    Auth.post("crud", {
      action: "select",
      table: "assessments",
      limit: 500
    })
      .then((res) => {
        if (res.data) {
          setAssessments(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Assessment Name
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Status</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessments.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.assessment_id}</TableCell>
                  <TableCell>{row.assessment_name}</TableCell>
                  {/* <TableCell align="right">
                    {row.status === "1" ? "Active" : "Inactive"}
                  </TableCell> */}
                  <TableCell align="right">
                    <Button
                      onClick={() => handleClickOpen(row)}
                      color="primary"
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {assessmentObject.assessment_name}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="count"
                label="Update Question Count"
                value={newTitle}
                onChange={(e) => setnewTitle(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdate} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default Manage;
