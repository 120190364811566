import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AuthService from "../auth/AuthService";
import { useSnackbar } from "notistack";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#ffffff",
    float: "left",
    position: "absolute",
    height: "40px",
    backgroundColor: "#3f51b5",
  },
}));

export default function FormDialog(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [questionCount, setQuestionCount] = useState(20);

  const classes = useStyles();

  useEffect(() => {
    // console.log("props.table", props.table);
    if (props.table !== "") setOpen(true);
  }, [props.table]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setTable("");
  };

  const saveObject = (e) => {
    let obj = {
      // school_id: 1,
      action: "create",
      table: props.table,
    };
    obj[props.keyName] = text;

    if (props.table === "subjects") {
      obj.grade_id = props.gradeSelect;
    } else if (props.table === "levels") {
      obj.grade_id = props.gradeSelect;
      obj.subject_id = props.subjectSelect;
    } else if (
      props.table === "assessments" ||
      props.table === "topics" ||
      props.table === "question_types"
    ) {
      if (props.table === "assessments") {
        obj.question_count = questionCount;
      }
      obj.grade_id = props.gradeSelect;
      obj.subject_id = props.subjectSelect;
      obj.level_id = props.levelSelect;
    } else if (props.table === "appclass") {
      obj.grade_id = props.classSelect;
      obj.creator_id = Auth.getProfile().user_id;
    } else if (props.table === "section") {
      obj.section_id = props.sectionSelect;
      obj.grade_id = props.classSelect;
    }

    console.log("obj", obj);
    Auth.post("crud", obj)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          setText("");
          setOpen(false);
          props.dataSaved({ id: res.data, name: text, table: props.table });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
    // selection(item)
  };

  return (
    <>
      {!props.hidden && (
        <AddBoxIcon className={classes.button} onClick={handleClickOpen} />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add {props.table}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={props.table}
            type="email"
            fullWidth
            onKeyUp={(e) => {
              setText(e.target.value);
            }}
          />
          {props.table === "assessments" && (
            <>
            <TextField
              id="question_count"
              label="Question Count"
              type="number"
              onChange={(e) => {
                setQuestionCount(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />
            </>
          )}
          <Button variant="outlined" color="primary" onClick={saveObject}>
            Create
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
