import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import AddQuestion from "./common/AddQuestion";
import QuestionDropDown from "./common/QuestionDropDown";
import QuestionSearch from "./common/QuestionSearch";

export default function Main(props) {
  const [selection, setSelection] = React.useState([]);
  const [popupHide, setPopupHide] = useState(true);
  const [editQ, setEditQ] = useState(null);

  const propPopupClosed = (param) => {
    // console.log("propPopupClosed, selection", param);
    setEditQ(null)
    setPopupHide(param);
  };

  const handleEdit = (item) => {
    setEditQ(item)
    setPopupHide(!popupHide);
  }

  return (
    <>
      <Grid item xs={12}>
        <h1>Manage Question</h1>
        {/* <AddQuestion selection={[1,1,1,1]} popupClosed={propPopupClosed} handleEdit={editQ} />
        <QuestionSearch selection={[1,1,1,1]} popupHide={popupHide} handleEdit={handleEdit} /> */}
      </Grid>
      <QuestionDropDown selection={setSelection} />
      {selection[0] && selection[1] && selection[2] && selection[3] ? (
        <>
          <AddQuestion selection={selection} popupClosed={propPopupClosed} handleEdit={editQ} />
          <QuestionSearch selection={selection} popupHide={popupHide} handleEdit={handleEdit} />
        </>
      ) : (
        ""
      )}
    </>
  );
}
