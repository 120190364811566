import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CreateModule from "./CreateModule";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const Auth = new AuthService();

export default function VideoDropDown({ selection }) {
  const classes = useStyles();
  const [table, setTable] = useState("");
  const [keyName, setKeyName] = useState("");

  const [grades, setGrades] = useState([]);
  const [gradeSelect, setGradeSelect] = useState("");

  const [subjects, setSubjects] = useState([]);
  const [subjectSelect, setSubjectSelect] = useState("");

  const [levels, setLevels] = useState([]);
  const [levelSelect, setLevelSelect] = useState("");

  const [topics, setTopics] = useState([]);
  const [topicsSelect, setTopicsSelect] = useState("");

  const setItems = (items, id, name) => {
    let lastItem = items.pop();
    items.push({
      id: id,
      name: name,
    });
    items.push(lastItem);
    return items;
  };

  const dataSaved = ({ id, name, table }) => {
    if (table === "grades") {
      setGrades(setItems(grades, id, name));
    } else if (table === "subjects") {
      setSubjects(setItems(subjects, id, name));
    } else if (table === "levels") {
      setLevels(setItems(levels, id, name));
    } else if (table === "topics") {
      setTopics(setItems(topics, id, name));
    }
    setTable("");
    setKeyName("");
  };

  useEffect(() => {
    Auth.post("crud", {
      // school_id: 1,
      table: "grades",
      action: "select",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.id,
            name: element.grade_name,
          });
        });
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Grade",
          });
        }
        setGrades(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setSubjects([]);
    setSubjectSelect("");
    setLevels([]);
    setLevelSelect("");
    setTopics([]);
    setTopicsSelect("");

    if (gradeSelect === 0) {
      setTable("grades");
      setKeyName("grade_name");
      return;
    }
    if (gradeSelect === "") return;

    Auth.post("crud", {
      // school_id: 1,
      id_key: "grade_id",
      id_value: gradeSelect,
      action: "select",
      table: "subjects",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          if (element.status === "1" || element.status === null) {
            items.push({
              id: element.subject_id,
              name: element.subject_name,
            });
          }
        });
        // console.log("class", res);
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Subject",
          });
        }

        setSubjects(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [gradeSelect]);

  useEffect(() => {
    // setSubjects([])
    // setSubjectSelect('')
    setLevels([]);
    setLevelSelect("");
    setTopics([]);
    setTopicsSelect("");

    if (subjectSelect === 0) {
      setTable("subjects");
      setKeyName("subject_name");
      return;
    }
    if (subjectSelect === "") return;

    Auth.post("crud", {
      // school_id: 1,
      id_key: "subject_id",
      id_value: subjectSelect,
      action: "select",
      table: "levels",
      order: "level_order",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          console.log("element => ", element);

          if (element.status === "1" || element.status === null) {
            items.push({
              id: element.level_id,
              name: element.level_name,
              // section_name: element.section_name,
            });
          }
        });
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Level",
          });
        }
        // console.log("levels", res);
        setLevels(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [subjectSelect]);

  useEffect(() => {
    // console.log("load topics...", levelSelect);
    // setSubjects([])
    // setSubjectSelect('')
    // setLevels([])
    // setLevelSelect('')
    setTopics([]);
    setTopicsSelect("");

    if (levelSelect === 0) {
      setTable("levels");
      setKeyName("level_name");
      return;
    }
    if (levelSelect === "") return;
    Auth.post("crud", {
      id_key: "level_id",
      id_value: levelSelect,
      action: "select",
      table: "topics",
      order: "topic_order",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          if (element.status === 1 || element.status === null) {
            items.push({
              id: element.id,
              name: element.name,

              // section_name: element.section_name,
            });
          }
        });
        // console.log("topics", res);
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Topics",
          });
        }

        setTopics(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [levelSelect]);

  useEffect(() => {
    // console.log("load topics...", levelSelect);

    if (topicsSelect === 0) {
      setTable("topics");
      setKeyName("name");
      return;
    }
  }, [topicsSelect]);

  useEffect(() => {
    selection([gradeSelect, subjectSelect, levelSelect, topicsSelect]);
  }, [gradeSelect, subjectSelect, levelSelect, topicsSelect, selection]);

  return (
    <>
      <CreateModule
        // style={{ float: "left" }}
        hidden={true}
        table={table}
        gradeSelect={gradeSelect}
        subjectSelect={subjectSelect}
        levelSelect={levelSelect}
        topicsSelect={topicsSelect}
        dataSaved={dataSaved}
        setTable={setTable}
        keyName={keyName}
      />
      <Grid item xs={6} sm={4} lg={3}>
        {grades.length ? (
          <Autocomplete
            id="combo-box-demo-1"
            options={grades}
            getOptionLabel={(option) => option.name}
            autoComplete
            openOnFocus
            onChange={(event, newValue) => {
              // console.log('event', event)
              setGradeSelect(newValue ? newValue.id : "");
              setLevelSelect("");
              setSubjectSelect("");
              setTopics("");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                style={{ float: "left" }}
                {...params}
                label="Grades List"
                variant="outlined"
                value={gradeSelect}
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} sm={4} lg={3}>
        {subjects.length ? (
          <Autocomplete
            id="combo-box-demo-2"
            options={subjects}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSubjectSelect(newValue ? newValue.id : "");
              setLevelSelect("");
              setTopics("");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField {...params} label="Subject List" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} sm={4} lg={3}>
        {levels.length ? (
          <Autocomplete
            id="combo-box-demo-2"
            options={levels}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setLevelSelect(newValue ? newValue.id : "");
              setTopics("");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Subject Level List"
                variant="outlined"
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} sm={4} lg={3}>
        {topics.length ? (
          <Autocomplete
            id="combo-box-demo-2"
            options={topics}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setTopicsSelect(newValue ? newValue.id : "");
              // selection([gradeSelect, subjectSelect, levelSelect, newValue.id]);
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField {...params} label="Topics List" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
