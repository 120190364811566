import React, { useState, useEffect } from "react";
// import { Grid } from "@material-ui/core";

// import AuthService from "../components/auth/AuthService";

// import Button from "@material-ui/core/Button";
import SyncCard from "../../common/lib/sync/SyncCard";
import List from "@material-ui/core/List";
// import SyncIcon from "@material-ui/icons/Sync";

import { listSyncVideos } from "../../common/lib/sync/videoSyncLib";

// const Auth = new AuthService();

export default function Main(props) {
  const [videosList, setVideosList] = useState([]);

  useEffect(() => {
    let timer = null;
    // console.log(props.blobContainer, "props.blobContainer");
    listSyncVideos('version')
      .then((videoList) => {
        console.log("set");
        setVideosList(videoList);
        // if (videosList.length) {
        timer = window.setInterval(() => {
          listSyncVideos('version')
            .then((videoList) => {
              console.log("set");
              setVideosList(videoList);
            })
            .catch((e) => {
              console.log("error", e);
            });
        }, 10000);
        console.log("set Interval", timer);
        // }
      })
      .catch((e) => {
        console.log("error", e);
      });
    return () => {
      console.log("clear Interval", timer);
      window.clearInterval(timer);
    };
  }, [props.blobContainer]);

  return (
    <>
      {/* <Grid item xs={12}>
        <h1>SYNCING</h1>
        <Button
          onClick={() => {
            syncAll(props.blobContainer);
          }}
          variant="contained"
          color="primary"
        >
          Sync All <SyncIcon />
        </Button>
      </Grid> */}

      {/* <Grid item xs={12}> */}
        <List>
          {videosList.length
            ? videosList.map((card, i) => <SyncCard key={i} videoItem={card} />)
            : "No Video Found For Syncing New Version."}
        </List>
      {/* </Grid> */}
    </>
  );
}
