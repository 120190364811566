import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuthService from '../../auth/AuthService';
import { Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import config from '../../../config'
import {
  listAssessments,
  listVideos,
  pushNewData,
  removeVideo,
  softDelete,
} from './sync/videoSyncLib';

import Container from './Container';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Button from '@material-ui/core/Button';
import { blobService, getBlobInContainer } from './uploadToBlob';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cursor: { cursor: 'pointer' },
  image: {
    height: 80,
  },
}));
const Auth = new AuthService();
const VideoUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [videosList, setVideosList] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  // const [video, setVideo] = useState(null);
 
  const handleUploadphp = async (file,path) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    try {
      const response = await fetch(`${config.domain}uploadvideo`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log("s3 data is :",data);

                // Step 2: Upload the file to S3 with progress tracking
                const stream = file.stream();
                const totalSize = file.size;
                let uploadedBytes = 0;
    
                const uploadResponse = await fetch(data.signurl, {
                    method: 'PUT',
                    body: new ReadableStream({
                        start(controller) {
                            const reader = stream.getReader();
    
                            async function read() {
                                const { done, value } = await reader.read();
    
                                if (done) {
                                    controller.close();
                                    return;
                                }
    
                                controller.enqueue(value);
                                uploadedBytes += value.length;
                                const percentCompleted = Math.round((uploadedBytes * 100) / totalSize);
                                setProgress(percentCompleted);
    
                                // Continue reading
                                read();
                            }
    
                            // Start reading
                            read();
                        },
                    }),
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                });

    if (uploadResponse.ok) {
        console.log('File uploaded successfully');
        // console.log('S3 key:', key);
    } else {
        console.error('Error uploading file:', response.statusText);
    }
      return data.key;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };



  const addAssessment = (newValue) => {
    if (!newValue) return;
    Auth.post('crud', {
      action: 'create',
      table: 'videos',
      video_link: `Assessment ${newValue.name}`,
      grade_id: props.blobContainer[0],
      subject_id: props.blobContainer[1],
      level_id: props.blobContainer[2],
      assessment_id: newValue.id,
      topic_id: props.blobContainer[3],
      status: 2,
      video_order: 1,
    })
      .then((res) => {
        if (res.status && res.data) {
          pushNewData(res.data).then((data) => {
            let newList = videosList;
            newList.push(data);
            setVideosList([]);
            setVideosList(newList);
          });
        } else {
          if (res.message === 'Assessment moved') {
            pushNewData(res.data).then((data) => {
              let newList = videosList;
              newList.push(data);
              setVideosList([]);
              setVideosList(newList);
            });
          }
          enqueueSnackbar(res.message, {
            variant: 'error',
          });
        }
        console.log('res', res);
      })
      .catch((e) => {
        console.error(e);
      });
    // let assessmentExist = videosList.filter((obj) => {
    //   return obj.assessment_id === newValue.id;
    // })[0];
    // if (assessmentExist === undefined) {

    // } else {
    //   enqueueSnackbar(`${assessmentExist.video_link} Already Added`);
    // }
  };

  useEffect(() => {
    listAssessments(props.blobContainer[2])
      .then((items) => {
        setAssessments(items);
      })
      .catch((e) => {
        console.log('error', e);
      });
    listVideos(props.blobContainer)
      .then((videoList) => {
        setVideosList([]);
        setVideosList(videoList);
      })
      .catch((e) => {
        console.log('error', e);
      });
  }, [props.blobContainer]);

  const onFileUpload = async (event) => {
    let file = event.target.files[0];
    if (!file.size) return;
    setUploading(true);
    console.log("props",props)
    const blobsInContainer = await handleUploadphp(file,props.blobContainer.join("-"));
    console.log("blobsInContainer",blobsInContainer)
    Auth.post('crud', {
      action: 'create',
      table: 'videos',
      video_link: blobsInContainer,
      grade_id: props.blobContainer[0],
      subject_id: props.blobContainer[1],
      level_id: props.blobContainer[2],
      topic_id: props.blobContainer[3],
      status: 1,
      video_order: 1,
      video_version: 1,
      total_size: file.size,
      uploaded_size: file.size,
    })
      .then((res) => {
        if (res.status) {
          setUploading(false);
          if (res.status && res.data) {
            pushNewData(res.data).then((data) => {
              let newList = videosList;
              newList.push(data);
              setVideosList([]);
              // console.log('videosList', videosList)
              // setVideosList(old => [...old, data]);
              setVideosList(newList);
            });
          }
        }
        console.log('res', res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const itemDeleted = (id) => {
    removeVideo(id)
      .then(() => {
        listVideos(props.blobContainer)
          .then((videoList) => {
            setVideosList([]);
            setVideosList(videoList);
          })
          .catch((e) => {
            console.log('error', e);
          });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
        // console.log("error", e);
      });
  };
  const itemSoftDelete = (id, deleted) => {
    softDelete(id, deleted)
      .then(() => {
        listVideos(props.blobContainer)
          .then((videoList) => {
            // console.log('videoList', videoList)
            setVideosList([]);
            setVideosList(videoList);
          })
          .catch((e) => {
            console.log('error', e);
          });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
        // console.log("error", e);
      });
  };

  const DisplayForm = () => (
    <Button className={classes.button} variant="contained" component="label">
      Video Upload
      <input
        type="file"
        accept="video/*"
        onChange={onFileUpload}
        style={{ display: 'none' }}
      />
    </Button>
  );

  const uploadToBlobProgress = async (file, containerName, folder) => {
    if (!file) return [];
    console.log('containerName', containerName);
    const containerClient = blobService.getContainerClient(containerName);
    await containerClient.createIfNotExists({
      access: 'container',
    });
    await createBlobInContainer(containerClient, file, folder);
    return getBlobInContainer(containerName, `${folder}/${file.name}`);
  };

  const createBlobInContainer = async (containerClient, file, folder) => {
    const blobClient = containerClient.getBlockBlobClient(
      `${folder}/${file.name}`
    );
    const options = {
      blockSize: 1 * 1024 * 1024,
      onProgress: (ev) => {
        // console.log("evevevevevevev###", ev, `${folder}/${file.name}`);
        // updateFileSize(`${folder}/${file.name}`, ev.loadedBytes)
        let percentage = parseInt((ev.loadedBytes / file.size) * 100);
        setProgress(percentage);
      },
      blobHTTPHeaders: { blobContentType: file.type },
    };
    await blobClient.uploadBrowserData(file, options);
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid container xs={12} spacing={4}>
        <Grid item xs={3} className={classes.center}>
          {!uploading && DisplayForm()}
          {uploading && (
            <div>
              Uploading ({progress}%)
              <LinearProgress variant="determinate" value={progress} />
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          {assessments.length ? (
            <Autocomplete
              id="assessments-2"
              options={assessments}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                addAssessment(newValue ? newValue : '');
              }}
              className={classes.paper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assessments List"
                  variant="outlined"
                />
              )}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <DndProvider backend={HTML5Backend}>
          {videosList.length ? (
            <Container
              itemSoftDelete={itemSoftDelete}
              itemDeleted={itemDeleted}
              videosList={videosList}
            />
          ) : (
            'No Data Found.'
          )}
        </DndProvider>
      </Grid>
    </>
  );
};

export default VideoUpload;
