import React from "react";
import QuestionType from "./common/questions/QuestionType"

export default function VideosQuestions() {

  const setSelection = (d) => {
    console.log('setSelection', d)
  }
 
  return (<QuestionType selection={setSelection} grade={1} subject={1} level={1} />);
}
