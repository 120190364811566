import AuthService from "../../../auth/AuthService";
const Auth = new AuthService();

  // console.log("id_value", id_value);
export const syncData = (type) => {
  return Auth.post("data-sync", {
    action: type,
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
