import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Grid } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import AuthService from "../../auth/AuthService";
import ImageUpload from "../lib/ImageUpload";
import useForm from "../lib/useForm";
import { useSnackbar } from "notistack";
import QuestionType from "./QuestionType";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  edit: {
    backgroundColor: "#e8e8e8",
  },
}));

const defaultValues = () => {
  return {
    question: "",
    ans: {
      1: "True",
      2: "False",
    },
    Qtype: "True False",
    CorrectAnswer: "1",
    image: {
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
    },
  };
};

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [values, handleChange, resetForm] = useForm(defaultValues);
  const [restImages, setRestImages] = useState(null);
  const [block, setBlock] = useState(false);
  const [editId, setEditId] = useState(0);
  const [questionTypeId, setQuestionTypeId] = useState(props.handleEdit ? props.handleEdit.question_type_id : 0);

  const blobItem = (value, name) => {
    handleChange({ target: { value, name }, custom: true });
  };

  useEffect(() => {
    if (block) return;
    setBlock(true);
    // console.log("handleEdit TF", props.handleEdit);
    if (props.handleEdit) {
      resetForm(props.handleEdit.question);
      setEditId(props.handleEdit.id);
    }
  }, [block, props.handleEdit, resetForm]);

  const register = (e) => {
    e.preventDefault();
    saveObject(values);
    // if (!editId) restObject();
  };

  const restObject = () => {
    resetForm(defaultValues);
    setRestImages(!restImages);
    setEditId(0);
  };

  const saveObject = (question) => {
    let obj = {
      action: "add-or-edit",
      table: "questions",
      grade_id: props.selection[0],
      subject_id: props.selection[1],
      level_id: props.selection[2],
      assessment_id: props.selection[3],
      question_type_id: questionTypeId,
      id_key: "questions_id",
      id_value: editId,
      date_time: true,
      question: JSON.stringify(question),
    };

    Auth.post("crud", obj)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          let newId = parseInt(res.data);
          // console.log("###", newId, editId);
          if (!editId) {
            enqueueSnackbar(`Question Added Successfully.`);
          } else if (newId !== parseInt(editId)) {
            enqueueSnackbar(
              `Old question '${editId}' was attempted, so new question ${newId} added.`,
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar(`${editId}: Question Edit Successfully.`);
          }

          setEditId(newId);
          // if (!editId) resetForm(defaultValues);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <form className={editId && classes.edit} onSubmit={register}>
      <h2>
        {editId ? `${editId}: Edit` : "Create"} Question Type {props.qType},
        Container "{props.selection.join("-")}"
      </h2>
      <QuestionType
        questionTypeId={setQuestionTypeId}
        defaultValue={{
          id: props.handleEdit ? props.handleEdit.question_type_id : "",
          name: props.handleEdit ? props.handleEdit.question_type_name : "",
        }}
        grade={props.selection[0]}
        subject={props.selection[1]}
        level={props.selection[2]}
      />

      <br />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={3}
            placeholder="Question"
            style={{ width: "100%" }}
            name="question"
            value={values.question || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <ImageUpload
            editImage={values.questionImage}
            blobContainer={props.selection}
            label="Select Question Image"
            blobItem={blobItem}
            name="questionImage"
            restImages={restImages}
          />
        </Grid>
        {[1, 2].map((x) => {
          return (
            <React.Fragment key={x}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Answer
                  </InputLabel>
                  <OutlinedInput
                    name={`ans--${x}`}
                    value={values.ans ? values.ans[x] : ""}
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        Option {x}
                      </InputAdornment>
                    }
                    labelWidth={60}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <ImageUpload
                  editImage={values["image"][x]}
                  blobContainer={props.selection}
                  label="Select Image"
                  blobItem={blobItem}
                  name={`image--${x}`}
                  restImages={restImages}
                />
              </Grid>
              <Grid item xs={3}>
                <Radio
                  checked={parseInt(values.CorrectAnswer) === x}
                  onChange={handleChange}
                  value={x}
                  name="CorrectAnswer"
                  // inputProps={{ "aria-label": "A" }}
                />
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          &nbsp;
          <Button variant="contained" color="secondary" onClick={restObject}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
