import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { syncData } from "../common/lib/sync/dataSyncLib"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function IconLabelButtons() {
  const classes = useStyles();

  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);

  return (
    <div>

      <Button
        variant="contained"
        color="default"
        disabled={disabled}
        className={classes.button}
        startIcon={<RotateLeftIcon />}
        onClick={()=>{
            setDisabled(true)
            setData([])
            syncData('recover_data').then((data) => {
                setData(data)
                setDisabled(false)
              });
        }}
      >
        Recover Data
      </Button>

      <Button
        variant="contained"
        color="default"
        disabled={disabled}
        className={classes.button}
        startIcon={<RotateLeftIcon />}
        onClick={()=>{
            setDisabled(true)
            setData([])
            syncData('sync-check').then((data) => {
                setData(data)
                setDisabled(false)
              });
        }}
      >
        Video Sync Check
      </Button>

      <Button
        variant="contained"
        color="default"
        disabled={disabled}
        className={classes.button}
        startIcon={<CloudUploadIcon />}
        onClick={()=>{
            setDisabled(true)
            setData([])
            syncData('push').then((data) => {
                setData(data)
                setDisabled(false)
              });
        }}
      >
        Push Data
      </Button>

      <Button
        variant="contained"
        color="default"
        disabled={disabled}
        className={classes.button}
        startIcon={<CloudDownload />}
        onClick={()=>{
            setDisabled(true)
            setData([])
            syncData('pull').then((data) => {
                setData(data)
                setDisabled(false)
              });
        }}
      >
        Pull Data
      </Button>
      
      <pre>{data.join('\n')}</pre>
      
    </div>
  );
}
