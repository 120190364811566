import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Container from "@material-ui/core/Container";
import MCQ from "./questions/MCQ";
import FITB from "./questions/FITB";
import TF from "./questions/TF";
import MC from "./questions/MC";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(10),
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddQuestion(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [typeOpen, setTypeOpen] = React.useState(false);
  const [qType, setQtype] = React.useState("Fill in the Blanks");

  useEffect(() => {
    console.log("addq props.handleEdit", props.handleEdit);
    if (props.handleEdit) {
      setQtype(props.handleEdit.question.Qtype);
      setOpen(true);
      // props.popupClosed(false);
    }
  }, [props, props.handleEdit]);

  const handleClickOpen = () => {
    setOpen(true);
    props.popupClosed(false);
  };

  const handleClose = () => {
    setOpen(false);
    props.popupClosed(true);
  };

  const handleChange = (event) => {
    setQtype(event.target.value);
  };

  const handleTypeClose = () => {
    setTypeOpen(false);
  };

  const handleTypeOpen = () => {
    setTypeOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Add New Question
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add Question
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <FormControl className={classes.formControl}>
            {!props.handleEdit ? (
              <>
                <InputLabel id="demo-controlled-open-select-label">
                  Question Type
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  name="Qtype"
                  open={typeOpen}
                  onClose={handleTypeClose}
                  onOpen={handleTypeOpen}
                  value={qType}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"MCQ"}>MCQ</MenuItem>
                  <MenuItem value={"True False"}>True False</MenuItem>
                  <MenuItem value={"Fill in the Blanks"}>
                    Fill in the Blank
                  </MenuItem>
                  <MenuItem value={"Match Column"}>Match Columns</MenuItem>
                </Select>
              </>
            ) : (
              ""
            )}

            {qType === "MCQ" && (
              <MCQ
                qType={qType}
                selection={props.selection}
                handleEdit={props.handleEdit}
              />
            )}
            {qType === "True False" && (
              <TF
                qType={qType}
                selection={props.selection}
                handleEdit={props.handleEdit}
              />
            )}
            {qType === "Fill in the Blanks" && (
              <FITB
                qType={qType}
                selection={props.selection}
                handleEdit={props.handleEdit}
              />
            )}
            {qType === "Match Column" && (
              <MC
                qType={qType}
                selection={props.selection}
                handleEdit={props.handleEdit}
              />
            )}
          </FormControl>
        </Dialog>
      </Grid>
    </Container>
  );
}
