const config = {
  dev_studio: {
    // imageCDN: "http://localhost:8082/index.php/image?image=",
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://localhost:8082/index.php/",
    videoEndPoint: "http://localhostF:8081/index.php/video?video_id=",
    app: 'Studio',
    pi: false
  },
  dev_admin: {
    // imageCDN: "http://localhost:8083/index.php/image?image=",
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://localhost:8083/index.php/",
    videoEndPoint: "http://localhost:8083/index.php/video?video_id=",
    app: 'Admin',
    pi: false
  },
  respberry: {
    imageCDN: "http://192.168.4.1/index.php/image?image=",
    domain: "http://192.168.4.1/index.php/",
    videoEndPoint: "http://192.168.4.1/index.php/video?video_id=",
    app: 'Studio',
    pi: true
  },
  admin: {
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://admin-api.msn72.com/index.php/",
    videoEndPoint: "http://admin-api.msn72.com/video?video_id=",
    app: 'Admin',
    pi: false
  },
  stage_admin: {
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://stage-admin-api.msn72.org/",
    videoEndPoint: "http://stage-admin-api.msn72.org/video?video_id=",
    app: 'Admin',
    pi: false
  },
  studio: {
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://studio-api.msn72.com/index.php/",
    videoEndPoint: "http://studio-api.msn72.org/video?video_id=",
    app: 'Studio',
    pi: false
  },
  stage_studio: {
    imageCDN: "https://test-mx-grand-store-new.s3.amazonaws.com/",
    domain: "http://stage-studio-api.msn72.org/index.php/",
    videoEndPoint: "http://stage-studio-api.msn72.org/index.php/video?video_id=",
    app: 'Studio',
    pi: false
  },
};
module.exports = config.studio;
