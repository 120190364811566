import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AuthService from './auth/AuthService';
import Message from './common/Message';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Auth = new AuthService();

export default function Metadata() {
  const classes = useStyles();
  const [school, setSchool] = React.useState('');
  const [schools, setSchools] = React.useState([]);
  const [module, setModule] = React.useState('');
  const [metaObjectsArr, setMetaObjectsArr] = React.useState([]); // List of metadata modules
  const [metaObject, setMetaObject] = React.useState(''); // selected metadata module
  const [updatedObject, setUpdatedObject] = React.useState(''); // value shown on frontend
  const [metaObjectStatus, setMetaObjectStatus] = React.useState(''); // value for enable/disable
  const [table, setTable] = React.useState(''); // table for selected metadata module
  const [attributeLabel, setAttributeLabel] = React.useState(''); // primary key label for the meta
  const [idLabel, setIdLabel] = React.useState(''); // primary key label for the meta
  const [idValue, setIdValue] = React.useState(''); // primary key value for the meta
  const [msg, setMsg] = React.useState('');
  const [msgType, setMsgType] = React.useState('');
  const [recType, setRecType] = React.useState('');

  useEffect(() => {
    Auth.post('getSchools', {
      // school_id: 1,
    })
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          setSchools(res.data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const handleChangeModule = (event) => {
    let value = event.target.value;
    setModule(event.target.value);

    let table = '';
    // let view_table = "";
    let attribute_label = '';
    let id_label = '';
    let rec_type = '0';
    if (value === 'grades') {
      table = 'grades';
      // view_table = "tv_grades_status";
      attribute_label = 'grade_name';
      id_label = 'id';
      rec_type = '1';
    }
    // else if (value === "sections") {
    //   table = "section";
    //   // view_table = "tv_sections_status";
    //   attribute_label = "section_name";
    //   id_label = "section_id";
    //   rec_type = "2";
    // }
    else if (value === 'levels') {
      table = 'levels';
      // view_table = "tv_levels_status";
      attribute_label = 'level_name';
      id_label = 'level_id';
      rec_type = '2';
    } else if (value === 'subjects') {
      table = 'subjects';
      // view_table = "tv_subjects_status";
      attribute_label = 'subject_name';
      id_label = 'subject_id';
      rec_type = '3';
    }

    setTable(table);
    setIdLabel(id_label);
    setAttributeLabel(attribute_label);
    // setAttributeLabel("name");
    setRecType(rec_type);

    if (table.length > 0) {
      Auth.post('metadata-update', {
        table: table,
        school_id: school,
      })
        .then((res) => {
          console.log('res.data', res.data);
          setMetaObjectsArr(res.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const handleSelectObject = (event) => {
    event.preventDefault();

    let value = event.target.value;
    setMetaObject(value);

    let object = metaObjectsArr.filter((obj) => {
      return obj.object_id === value;
    });
    setUpdatedObject(object[0]['name']); // initial update value
    setIdValue(object[0]['object_id']);
    setMetaObjectStatus(object[0]['status'] === '1' ? true : false); // set enable/disable
  };

  const handleChangeObject = (event) => {
    setUpdatedObject(event.target.value);
  };

  const handleChangeStatus = (event) => {
    console.log(event.target.checked);
    setMetaObjectStatus(event.target.checked);
  };

  const handleSave = () => {
    Auth.post('crud', {
      action: 'update',
      table: table,
      id_key: idLabel,
      id_value: idValue,
      [attributeLabel]: updatedObject,
    })
      .then((res) => {
        if (res.status) {
          setMsg('');
          Auth.post('update-metadata-status', {
            object_id: idValue,
            school_id: school,
            status: metaObjectStatus ? 1 : 0,
            type: recType,
          })
            .then((res) => {
              if (res.status) {
                setMetaObjectsArr((prevMetaDataObjectsArr) =>
                  prevMetaDataObjectsArr.map((o, i) => {
                    if (o.object_id === idValue) {
                      return {
                        ...o,
                        name: updatedObject,
                        status: metaObjectStatus ? '1' : '0',
                      };
                    }
                    return o;
                  })
                );

                setMsg('Record updated successfully');
                setMsgType('success');
              } else {
                setMsg('Record not updated');
                setMsgType('error');
              }
            })
            .catch((e) => {
              setMsg('Record not updated');
              setMsgType('error');
              console.error(e);
            });
        } else {
          setMsg('Record not updated');
          setMsgType('error');
        }
      })
      .catch((e) => {
        setMsg('Record not updated');
        setMsgType('error');
        console.error(e);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Select Schools
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={school}
              onChange={(e) => {
                setSchool(e.target.value);
                setModule('');
                setMetaObjectsArr([]);
              }}
            >
              {schools.map((obj, index) => (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {school && (
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Select Module
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={module}
                onChange={handleChangeModule}
              >
                <MenuItem key={1} value={'grades'}>
                  Grades
                </MenuItem>
                <MenuItem key={2} value={'levels'}>
                  Levels
                </MenuItem>
                {/* <MenuItem key={2} value={"sections"}>
                  Sections
                </MenuItem> */}
                <MenuItem key={3} value={'subjects'}>
                  Subjects
                </MenuItem>
              </Select>
            </FormControl>
          )}
          {module.length > 0 && (
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Select Object
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={metaObject}
                onChange={handleSelectObject}
              >
                {metaObjectsArr.map((obj, index) => (
                  <MenuItem key={index} value={obj.object_id}>
                    {obj.grade_name ? `${obj.grade_name}: ` : ''} {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {metaObject.length > 0 && (
            <div>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Disable</Grid>
                  <Grid item>
                    <Switch
                      checked={metaObjectStatus}
                      onChange={handleChangeStatus}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  <Grid item>Enable</Grid>
                </Grid>
              </Typography>

              <TextField
                autoFocus
                margin="dense"
                id="object"
                name="object"
                label="Update"
                type="text"
                value={updatedObject || ''}
                fullWidth
                onChange={handleChangeObject}
              />
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          )}
          <Message msg={msg} type={msgType} />
        </div>
      </Grid>
      <Grid item xs={6}>
        {metaObjectsArr.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metaObjectsArr.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor:
                        row.status === '1' ? 'lightgreen' : '#ff6966',
                    }}
                  >
                    <TableCell>
                      {row.grade_name ? `${row.grade_name}: ` : ''} {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.status === '1' ? 'Enabled' : 'Disabled'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
}
