import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import AuthService from "../auth/AuthService";
import { Grid, Button } from "@material-ui/core";
import PromotionDropDown from "./PromotionDropDown";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Main({ grade, section }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const [selection, setSelection] = useState(["", ""]);
  const [students, setStudents] = useState([]);
  // const [btnDisabled, setBtnDisabled] = useState(false);
  // const classes = useStyles();

  const upgradestudents = (grade_id, section_id, reason_id) => {
    if (checked.length === 0) return;
    Auth.post("update-student-grade", {
      student_ids: checked.join(","),
      grade_id: grade_id,
      section_id: section_id,
      reason_id: reason_id,
    })
      .then((res) => {
        console.log("resres", res);
        setChecked([]);
        Auth.post("students", {
          grade_id: grade,
          section_id: section,
        })
          .then((res) => {
            // setBtnDisabled(false)
            if (res.status) setStudents(res.data);
            else setStudents([]);
          })
          .catch((e) => {
            console.error(e);
          });
        // if (res.status) setStudents(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    setStudents([]);
    console.log("grade && section", grade, section);
    if (grade && section) {
      Auth.post("students", {
        grade_id: grade,
        section_id: section,
      })
        .then((res) => {
          console.log("res", res);
          if (res.status) setStudents(res.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [grade, section]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Grid item xs={12}>
        <h1>Promotion</h1>
      </Grid>
      <PromotionDropDown grade_id={grade} selection={setSelection} />

      {selection[0] && selection[1] && selection[2] ? (
        <>
          <Grid item xs={12}>
            <List>
              {students.map((row, index) => (
                <ListItem
                  key={row.student_id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(row.student_id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(row.student_id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": row.student_id }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    id={row.student_id}
                    primary={row.student_name}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <CommentIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                upgradestudents(selection[0], selection[1], selection[2]);
              }}
              style={{ float: "right", marginBottom: "20px" }}
              variant="contained"
              color="primary"
              className={classes.nextButton}
              disabled={!checked.length}
            >
              Upgrade Students
            </Button>
          </Grid>
        </>
      ) : (
        ""
      )}
    </>
  );
}
