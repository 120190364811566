import React, { Component } from "react";

import AuthService from "../auth/AuthService";
import AddUser from "./AddUser";
import EditUser from "./EditUser";

import Message from "../common/Message";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pageUsers: [],
      loadMessage: "",
      type: "success",
      currentPage: 0,
      rowsPerPage: 10,
    };

    this.msgInterval = null;
    this.Auth = new AuthService();
  }

  componentDidMount() {
    if (!this.Auth.loggedIn()) this.props.history.replace("/");
    this.setState({ loadMessage: "Loading Users", type: "warning" });
    this.fetchData("Users Loaded.");
  }

  deleteUser(id) {
    console.log("delete clicked");
    this.Auth.post("crud", {
      action: "update",
      table: "users",
      id_key: "user_id",
      id_value: id,
      status: "1",
    })
      .then((res) => {
        if (res.success === false) {
          this.setState({ isloading: false, errors: { form: res.message } });
        } else window.location.reload();
      })
      .catch((err) => {
        this.setState({ isloading: false, errors: { form: err } });
      });
  }

  updateUser(id) {
    console.log("update clicked");
  }

  fetchData(msg) {
    //   this.Auth.fetch("/api/user/list")
    //     .then(res => {
    //       this.setState({
    //         users: res.users,
    //         loadMessage: "Users Loaded",
    //         type: "success",
    //       });
    //     })
    //     .catch(err => {
    //       this.setState({
    //         loadMessage: "some error occurred please try again",
    //         type: "error",
    //       });
    //       console.log(err);
    //     });

    this.Auth.post("crud", {
      action: "select",
      table: "tv_users",
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            users: res.data,
            loadMessage: "Users Loaded",
            type: "success",
          });
          console.log(res.data);
          this.setCurrenPageUsers();
        }

        console.log("res", res);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  setCurrenPageUsers() {
    const usersPerPage = this.state.rowsPerPage;
    const pageVisited = this.state.currentPage * usersPerPage;
    const allUsers = this.state.users;
    const currentUsers = allUsers.slice(
      pageVisited,
      pageVisited + usersPerPage
    );
    this.setState({
      pageUsers: currentUsers,
    });
    // console.log(currentUsers);
  }

  async handleChangePage(e, newPage) {
    await this.setState({
      currentPage: newPage,
    });
    this.setCurrenPageUsers();
  }

  async handleRowChange(e) {
    // console.log("row");
    const newRow = parseInt(e.target.value, 10);
    await this.setState({
      currentPage: 0,
      rowsPerPage: newRow,
    });
    this.setCurrenPageUsers();
  }

  render() {
    // const { classes } = this.props;
    return (
      <div style={{ padding: "20px" }}>
        <AddUser propAddLink={this.propAddLink} plus={true} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>First Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>CNIC</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Contact</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>DOB</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Education</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Experience</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Belongs to community
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Training</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Attendence</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Gender</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Role</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>School</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Teacher Education
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Teaching Exp
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Teacher Training
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Belongs to Community
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Teacher Attendance
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="right">
                  Actions{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.pageUsers.map((row, index) =>
                row.status === "0" ? (
                  <TableRow key={index}>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.cnic}</TableCell>
                    <TableCell>{row.contact}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.dob}</TableCell>
                    <TableCell>{row.education}</TableCell>
                    <TableCell>{row.experience}</TableCell>
                    <TableCell>
                      {row.belongs_to_community === "1" ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.training === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.attendence}</TableCell>
                    <TableCell>
                      {row.gender === "1" ? "Male" : "Female"}
                    </TableCell>
                    <TableCell>
                      {row.role === "1" && "Admin"}{" "}
                      {row.role === "2" && "Teacher"}
                    </TableCell>
                    <TableCell>{row.school_name}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>No</TableCell>
                    <TableCell>No</TableCell>
                    <TableCell>No</TableCell>
                    <TableCell align="right">
                      <Tooltip title="delete">
                        <DeleteIcon
                          style={{ cursor: "pointer", float: "right" }}
                          onClick={(event) => {
                            if (
                              window.confirm(
                                "Are you sure you wish to delete this user?"
                              )
                            )
                              this.deleteUser(row.user_id);
                          }}
                        />
                      </Tooltip>
                      <EditUser
                        propAddLink={this.propAddLink}
                        plus={true}
                        user_id={row.user_id}
                        email={row.email}
                        first_name={row.first_name}
                        last_name={row.last_name}
                        role={row.role}
                        cnic={row.cnic}
                        contact={row.contact}
                        address={row.address}
                        gender={row.gender}
                        dob={row.dob}
                        education={row.education}
                        experience={row.experience}
                        training={row.training}
                        belongs_to_community={row.belongs_to_community}
                        attendence={row.attendence}
                      />
                    </TableCell>
                  </TableRow>
                ) : null
              )}

              <TablePagination
                count={this.state.users.length}
                page={this.state.currentPage}
                onChangePage={(e, p) => this.handleChangePage(e, p)}
                rowsPerPage={this.state.rowsPerPage}
                onChangeRowsPerPage={(e) => this.handleRowChange(e)}
                // onRowsPerPageChange={this.handleRowChange}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <Message msg={this.state.loadMessage} type={this.state.type} />
      </div>
    );
  }
}

export default ListUsers;
