import React from "react";
import { Grid } from "@material-ui/core";

// import AuthService from "../components/auth/AuthService";

import LevelDropDown from "../common/LevelDropDown";
import LevelContainer from "../common/lib/LevelContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// const Auth = new AuthService();

export default function Main(props) {
  // const [selection, setSelection] = React.useState([]);
  const [levelsList, setLevelsList] = React.useState([]);
  const levelProps = (param) => {
    console.log("levelProps", param);
    setLevelsList([]);
    setLevelsList(param);
  };
  return (
    <>
      <Grid item xs={12}>
        <h1>Level Sorting</h1>
      </Grid>
      <LevelDropDown levelProps={levelProps} />
      <Grid item xs={12}>
        <DndProvider backend={HTML5Backend}>
          {levelsList.length ? <LevelContainer list={levelsList} /> : ""}
        </DndProvider>
      </Grid>
    </>
  );
}
