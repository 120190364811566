import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import SortIcon from '@material-ui/icons/Sort';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import SyncIcon from '@material-ui/icons/Sync';
import GroupIcon from '@material-ui/icons/Group';
import StorageIcon from '@material-ui/icons/Storage';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AuthService from '../auth/AuthService';
import Tabs from '../common/Tabs';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import SchoolIcon from '@material-ui/icons/School';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AssessmentIcon from '@material-ui/icons/Assessment';

import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import { ThingsProvider } from './thingsContext';
import { SnackbarProvider } from 'notistack';
import SettingsIcon from '@material-ui/icons/Settings';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import config from '../../config';

const AUTH = new AuthService();

const drawerWidth = 16 * 15;
const drawerWidthRight = config.app === 'Studio' ? 16 * 22 : 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidthRight}px)`,
    marginRight: drawerWidthRight,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftLeft: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftBoth: {
    width: `calc(100% - ${drawerWidth + drawerWidthRight}px)`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperRight: {
    width: drawerWidthRight,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidthRight,
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function PersistentDrawerLeft({ children, attendance }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false); // @todo fix this localStorage.getItem('drawer_open')
  const [openRight, setOpenRight] = useState(false); // @todo fix this localStorage.getItem('drawer_open')
  // const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const loginOpen = Boolean(anchorEl);

  React.useEffect(() => {
    console.log('Auth.getProfile()', AUTH.getProfile());
  }, []);

  const [things, setThings] = useState([]);

  const handleLeftDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('drawer_open', 1);
  };

  const handleLeftDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('drawer_open', 0);
  };

  const handleRightDrawerOpen = () => {
    setOpenRight(true);
    localStorage.setItem('drawer_open_right', 1);
  };

  const handleRightDrawerClose = () => {
    setOpenRight(false);
    localStorage.setItem('drawer_open_right', 0);
    console.log(localStorage.getItem('drawer_open_right', 0));
  };

  const handleChange = (event) => {
    localStorage.removeItem('id_token');
    localStorage.setItem('drawer_open', 0);
    localStorage.setItem('drawer_open_right', 0);
    window.location.reload();
    // AUTH.logout(); @TODO
    // setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>
        <ThingsProvider value={[things, setThings]}>
          <CssBaseline />
          <AppBar
            position="fixed"
            color={config.app === 'Admin' ? 'secondary' : 'primary'}
            className={clsx(
              // classes.appBar,
              {
                [classes.appBarShift]: openRight,
                [classes.appBarShiftLeft]: open,
                [classes.appBarShiftBoth]: open && openRight,
              }
            )}
          >
            <Toolbar>
              {AUTH.loggedIn() && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleLeftDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography className={classes.title} variant="h6" noWrap>
                MSN72 {config.app}{' '}
                {AUTH.loggedIn() && ' (' + AUTH.getProfile().school_name + ')'}
              </Typography>
              {AUTH.loggedIn() && (
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={loginOpen}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleChange}>Logout</MenuItem>
                    <MenuItem onClick={handleClose}>
                      {AUTH.getProfile().email}
                    </MenuItem>
                  </Menu>
                </div>
              )}
              {AUTH.loggedIn() && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleRightDrawerOpen}
                  edge="start"
                  className={clsx(
                    classes.menuButton,
                    openRight && classes.hide
                  )}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          {AUTH.loggedIn() && (
            <>
              <Drawer
                // className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleLeftDrawerClose}>
                    {theme.direction === 'ltr' ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  {config.app === 'Studio' && (
                    <>
                      <ListItem button component={Link} to="/media" key="Media">
                        <ListItemIcon>
                          <SubscriptionsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Media Player" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/session-close"
                        key="Media"
                      >
                        <ListItemIcon>
                          <ThumbUpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Session Close" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/video-log-list"
                        key="logs"
                      >
                        <ListItemIcon>
                          <StorageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logs" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/students"
                        key="students"
                      >
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Students" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/student-attendance"
                        key="student-attendance"
                      >
                        <ListItemIcon>
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Students Attendance" />
                      </ListItem>

                      <ListItem
                        button
                        component={Link}
                        to="/settings"
                        key="settings"
                      >
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </ListItem>
                      <Divider />
                    </>
                  )}
                  <ListItem button component={Link} to="/syncing" key="Syncing">
                    <ListItemIcon>
                      <SyncIcon />
                    </ListItemIcon>
                    <ListItemText primary="Video Syncing" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/syncing-all"
                    key="SyncingAll"
                  >
                    <ListItemIcon>
                      <SyncIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="All Video Syncing" />
                  </ListItem>

                  {AUTH.getAdminType() && (
                    <>
                      {config.app === 'Admin' && (
                        <>
                          <Divider />
                          <ListItem
                            button
                            component={Link}
                            to="/question"
                            key="question"
                          >
                            <ListItemIcon>
                              <DeveloperBoardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Question" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/videos-question"
                            key="videos-question"
                          >
                            <ListItemIcon>
                              <VideogameAssetIcon />
                            </ListItemIcon>
                            <ListItemText primary="Videos Question" />
                          </ListItem>

                          <ListItem
                            button
                            component={Link}
                            to="/uploader"
                            key="Uploader"
                          >
                            <ListItemIcon>
                              <LowPriorityIcon />
                            </ListItemIcon>
                            <ListItemText primary="Queue Management" />
                          </ListItem>

                          <ListItem
                            button
                            component={Link}
                            to="/level-sorting"
                            key="level-sorting"
                          >
                            <ListItemIcon>
                              <SortIcon />
                            </ListItemIcon>
                            <ListItemText primary="Level Sorting" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/topic-sorting"
                            key="topic-sorting"
                          >
                            <ListItemIcon>
                              <SortIcon />
                            </ListItemIcon>
                            <ListItemText primary="Topic Sorting" />
                          </ListItem>

                          <ListItem
                            button
                            component={Link}
                            to="/schools"
                            key="schools"
                          >
                            <ListItemIcon>
                              <SchoolIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schools" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/users"
                            key="users"
                          >
                            <ListItemIcon>
                              <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/questions-health"
                            key="questions-health"
                          >
                            <ListItemIcon>
                              <FavoriteBorderIcon />
                            </ListItemIcon>
                            <ListItemText primary="Questions Health" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/questions-count"
                            key="questions-count"
                          >
                            <ListItemIcon>
                              <FormatListNumberedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Questions Count" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/metadata"
                            key="MetaData"
                          >
                            <ListItemIcon>
                              <PermDataSettingIcon />
                            </ListItemIcon>
                            <ListItemText primary="Metadata Management" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/manage-assessment"
                            key="manage-assessment"
                          >
                            <ListItemIcon>
                              <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Manage Assessments" />
                          </ListItem>
                        </>
                      )}
                    </>
                  )}
                </List>
              </Drawer>
              <Drawer
                variant="persistent"
                anchor="right"
                open={openRight}
                classes={{
                  paper: classes.drawerPaperRight,
                }}
              >
                <div className={classes.drawerPaper}>
                  <IconButton onClick={handleRightDrawerClose}>
                    <ChevronRightIcon />
                  </IconButton>
                </div>
                <Divider />
                <Tabs attendance={attendance} />
              </Drawer>
            </>
          )}
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openRight,
              [classes.contentShiftLeft]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Grid container spacing={3}>
              {children}
            </Grid>
          </main>
        </ThingsProvider>
      </div>
    </SnackbarProvider>
  );
}
