import React, { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@material-ui/core";
import AuthService from "../auth/AuthService";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../common/Message";

const Auth = new AuthService();

const Attendance = () => {
  const [grades, setGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(0);
  const [selectedSection, setSelectedSection] = useState(0);
  const [snackbarStatus, setSnackbarStatus] = useState(true);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    Auth.post("classes")
      .then((res) => {
        // console.log(res.data);
        setGrades(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // console.log("getting sections for", selectedGrade)
    Auth.post("sections", {
      grade_id: selectedGrade,
    })
      .then((res) => {
        //  console.log(res)
        setSections(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedGrade]);

  useEffect(() => {
    const school_id = Auth.getProfile().school_id;
    Auth.post("students", {
      grade_id: selectedGrade,
      section_id: selectedSection,
      school_id,
    })
      .then((res) => {
        console.log("these are students", res.data);
        setStudents(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedSection, selectedGrade]);

  const toggleCheck = (id) => {
    if (presentStudents.includes(id)) {
      setPresentStudents(presentStudents.filter((s) => s.student_id === id));
    } else {
      console.log("ADd");
      setPresentStudents([...presentStudents, id]);
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const uploadAttendance = () => {
    const data = {
      PresentStudents: JSON.stringify(presentStudents),
      grade_id: selectedGrade,
      section_id: selectedSection,
      attendence_date: formatDate(Date.now()),
    };

    console.log(data);
    Auth.post("uploadAttendence", data)
      .then((res) => {
        setSnackbarStatus(true);
        setMsg("Attendance Uploaded Successfully!");
        setMsgType("success");
        setTimeout(() => {
          setSnackbarStatus(false);
        }, 2500);
      })
      .catch((err) => {
        setSnackbarStatus(true);
        setMsg("Error Uploading attendance!");
        setMsgType("error");
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setSnackbarStatus(false);
        }, 3500);
      });
  };

  return (
    <Container>
      <Grid item xs={3} sm={3} lg={3}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">Grade</InputLabel>

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            placeholder="Grade"
          >
            {/* onChange={handleChange} */}
            {grades.map((grade) => {
              return (
                <MenuItem
                  value={grade.grade_id}
                  onClick={() => setSelectedGrade(grade.grade_id)}
                >
                  {grade.class_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
            >
              {sections.length > 0 ? (
                sections.map((section) => {
                  return (
                    <MenuItem
                      value={section.section_id}
                      onClick={() => setSelectedSection(section.section_id)}
                    >
                      {section.section_name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem>No Sections Available</MenuItem>
              )}
            </Select>
          </FormControl>
        }
      </Grid>
      <FormControl component="fieldset">
        {students.length > 0 ? (
          students.map((student) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        presentStudents.includes(student.student_roll_no)
                          ? true
                          : false
                      }
                      onClick={() => toggleCheck(student.student_roll_no)}
                      name="gilad"
                    />
                  }
                  label={student.student_name}
                />
              </FormGroup>
            );
          })
        ) : (
          <Typography variant="p">
            Please select a section that has students
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={presentStudents.length === 0}
          onClick={uploadAttendance}
        >
          Upload Attendance
        </Button>
      </FormControl>
      {snackbarStatus ? <Message msg={msg} type={msgType} /> : <> </>}
    </Container>
  );
};

export default Attendance;
