import React, { useState, useCallback } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { List, Grid } from "@material-ui/core";
import _ from "lodash";
import AssessmentHealthDropDown from "./AssessmentHealthDropDown.js";
import { LinearProgress } from "@material-ui/core";
import "./AssessmentHealth.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const Health = () => {
  const classes = useStyles();
  const [assessments, setAssessments] = useState([]);

  // const handleOpen = (id) => {
  //   const data = assessments.map((assessment) => {
  //     if (assessment.assessment_id === id) {
  //       assessment.open = !assessment.open;
  //       return assessment;
  //     }
  //     return assessment;
  //   });
  //   setAssessments(data);
  // };

  const setParentAssessments = useCallback((items) => {
    let allData1 = {};
    items.map((data) => {
      let id = `${data.assessment_id}-${data.assessment_name}-${data.question_count}`;
      allData1[id] = allData1[id] || [];
      allData1[id].push({
        type_count: data.type_count,
        question_type_name: data.question_type_name,
      });
      data.open = false;
      let typesArray = [];
      let typesObj = _.countBy(data.questions, "type_name");
      _.flatMap(typesObj, (val, type) => {
        const obj = {
          name: type,
          count: val,
          id: Math.random(), //TODO: Better way to get the id? type.question_type_id not working...
        };
        typesArray.push(obj);
      });

      data.question_types = typesArray;
      return data;
    });
    // setAllData(allData1);
    console.log("allData1##", allData1);
    // console.log("data##", allData);
    setAssessments(allData1);
  }, []);

  const getHealth = (groups, questionCount) => {
    let healthCounter = 0;
    groups.forEach((group) => {
      healthCounter += group.type_count >= 3 ? 1 : 0;
    });
    const health = ((healthCounter / questionCount) * 100).toFixed(1);
    return Number(health);
  };

  return (
    <>
      <Grid item xs={12}>
        <h1>Questions Health</h1>
      </Grid>
      <AssessmentHealthDropDown setParentAssessments={setParentAssessments} />
      <Grid item xs={12}>
        <List>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Assessment Name</StyledTableCell>
                  <StyledTableCell align="center">Group(s)</StyledTableCell>
                  <StyledTableCell align="left">Question Count</StyledTableCell>
                  <StyledTableCell align="center">Health</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(assessments).map((key, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {key.split("-")[1]}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="type_group">
                        {assessments[key].map((type, i) => {
                          return (
                            <p className="type_name" key={i}>
                              {type.question_type_name} ({type.type_count})
                            </p>
                          );
                        })}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <b>{key.split("-").pop()}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <LinearProgress
                        variant="determinate"
                        value={getHealth(
                          assessments[key],
                          key.split("-").pop()
                        )}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {/* <StyledTableRow>
                 <StyledTableCell align="right">
                    Assessment Health: <LinearProgress variant="determinate" value={10} />
                    </StyledTableCell>
                  </StyledTableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </List>
      </Grid>
    </>
  );
};
export default Health;
