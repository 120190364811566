import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import config from "../../../config";
import AuthService from "../../auth/AuthService";
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  card: {
    // maxWidth: 600,
    marginBottom: 10,
  },
  img: {
    width: 50,
  },
}));

export const getQuestions = (selection, videoId) => {
  return Auth.post("getVideoQuestions", {
    grade_id: selection[0],
    subject_id: selection[1],
    level_id: selection[2],
    topic_id: selection[3],
    video_id: videoId,
  })
    .then((res) => {
      let seekQuestions = [];
      if (res.status) {
        res.data.forEach((element) => {
          let se = parseInt(element.seek);
          seekQuestions.push({
            id: element.id,
            question_id: element.question_id,
            seek: se,
            question: JSON.parse(element.question),
          });
        });
      }
      return Promise.resolve(seekQuestions);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const removeVideoQuesion = (item) => {
  return Auth.post("crud", {
    table: 'video_questions',
    id_key: 'id',
    id_value: item.id,
    action: 'del',
  })
    .then((res) => {
      if (res.status) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
      
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const QuestionCard = ({ item }) => {
  const classes = useStyles();
  return (
    <CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.id}: {item.question.Qtype} ({item.question_type_name})
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {item.question.Qtype === "Fill in the Blanks" &&
            item.question.questionPart1 + " __ " + item.question.questionPart2}
          {(item.question.Qtype === "MCQ" ||
            item.question.Qtype === "Match Column" ||
            item.question.Qtype === "True False") &&
            item.question.question}
          {/* {item.question.Qtype === "Match Column" && (item.question.question) } */}
        </Typography>
      </CardContent>
      {/* <TableContainer component={Paper}> */}
      {item.question.image && (
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              {Object.keys(item.question.image).map((key, index) => {
                return (
                  <TableCell key={index} align="center">
                    {item.question.image[key] !== "null" && (
                      <img
                        className={classes.img}
                        alt={item.question.image[key]}
                        src={`${config.imageCDN}${item.question.image[key]}`}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}

      {item.question["image-match"] && (
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              {Object.keys(item.question["image-match"]).map((key, index) => {
                return (
                  <TableCell key={index} align="center">
                    {item.question["image-match"][key] !== "null" && (
                      <img
                        className={classes.img}
                        alt={item.question["image-match"][key]}
                        src={`${config.imageCDN}${item.question["image-match"][key]}`}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {Object.keys(item.question.col).map((key, index) => {
                return (
                  <TableCell key={index} align="center">
                    {item.question.col[key] !== "null" &&
                      item.question.col[key]}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {item.question.ans && (
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <b>Answer:</b>
              </TableCell>

              {Object.keys(item.question.ans).map((key, index) => {
                return (
                  item.question.ans[key] !== "null" && (
                    <TableCell key={index} align="left">
                      {item.question.ans[key]}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {/* <TableContainer /> */}
    </CardActionArea>
  );
};
