import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../auth/AuthService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CreateModule from "../common/CreateModule";
import config from "../../config";
// import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Auth = new AuthService();

export default function Main({ grade_id, selection }) {
  const classes = useStyles();

  const [table, setTable] = useState("");
  const [keyName, setKeyName] = useState("");
  const [grades, setGrades] = useState([]);
  const [classSelect, setClassSelect] = useState("");
  const [section, setSection] = useState([]);
  const [sectionSelect, setSectionSelect] = useState("");
  const [reason, setReason] = useState([]);
  const [reasonSelect, setReasonSelect] = useState("");

  const setItems = (items, id, name) => {
    let lastItem = items.pop();
    items.push({
      id: id,
      name: name,
    });
    items.push(lastItem);
    return items;
  };

  const dataSaved = ({ id, name, table }) => {
    if (table === "grades") {
      setGrades(setItems(grades, id, name));
    } else if (table === "section") {
      setSection(setItems(section, id, name));
    }
    setTable("");
    setKeyName("");
  };

  useEffect(() => {
    // console.log("load classes...");
    setGrades([])
    setSection([])
    setClassSelect("")
    setSectionSelect("")
    if(reasonSelect === "") return
    Auth.post("classes", {
      reason: reasonSelect,
      grade_id: grade_id
    })
      .then((res) => {
        let items = [];
        if (res.status) {
          res.data.forEach((element) => {
            items.push({
              id: element.grade_id,
              name: element.class_name,
            });
          });
        }
        if (config.app === "Admin") {
          items.push({
            id: 0,
            name: "Add New Class",
          });
        }

        console.log("class", res);
        setGrades(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [reasonSelect, grade_id]);

  useEffect(() => {
    setSection([]);
    setSectionSelect("");
    if (classSelect === 0) {
      setTable("grades");
      setKeyName("grade_name");
      return;
    }
    if (classSelect === "") return;

    Auth.post("sections", {
      // school_id: 1,
      grade_id: classSelect,
    })
      .then((res) => {
        console.log("class", res);
        let items = [];
        if (res.status)
          res.data.forEach((element) => {
            items.push({
              id: element.section_id,
              name: element.section_name,
            });
          });
        items.push({
          id: 0,
          name: "Add New section",
        });

        setSection(items);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [classSelect]);

  useEffect(() => {
    if (sectionSelect === 0) {
      setTable("section");
      setKeyName("section_name");
      return;
    }
    Auth.post("crud", {
      action: "select",
      table: "reasons",
    })
      .then((res) => {
        let items = [];
        res.data.forEach((element) => {
          items.push({
            id: element.id,
            name: element.reason,
          });
        });
        setReason(items);
        console.log("class", res);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [classSelect, sectionSelect]);


  useEffect(() => {
    selection([classSelect, sectionSelect, reasonSelect]);
  }, [selection, classSelect, sectionSelect, reasonSelect]);

  return (
    <>
      <CreateModule
        // style={{ float: "left" }}
        hidden={true}
        table={table}
        classSelect={classSelect}
        sectionSelect={sectionSelect}
        // subjectSelect={subjectSelect}
        dataSaved={dataSaved}
        setTable={setTable}
        keyName={keyName}
      />
      <Grid item xs={6} sm={4} lg={3}>
        {reason.length ? (
          <Autocomplete
            id="combo-box-demo-3"
            options={reason}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              // console.log('newValue', newValue)
              setReasonSelect(newValue ? newValue.id : "");
              // selection([classSelect, sectionSelect, newValue.id]);
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Promotion Reasons"
                variant="outlined"
              />
            )}
          />
        ) : (
          ""
        )}
      </Grid>
      
      <Grid item xs={6} sm={4} lg={3}>
        {grades.length ? (
          <Autocomplete
            id="combo-box-demo-1"
            options={grades}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setClassSelect(newValue ? newValue.id : "");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField {...params} label="To Grade" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} sm={4} lg={3}>
        {section.length ? (
          <Autocomplete
            id="combo-box-demo-2"
            options={section}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSectionSelect(newValue ? newValue.id : "");
            }}
            className={classes.paper}
            renderInput={(params) => (
              <TextField {...params} label="To Section" variant="outlined" />
            )}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
