import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
// import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import FastfoodIcon from "@material-ui/icons/Fastfood";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
// import LaptopMacIcon from "@material-ui/icons/LaptopMac";
// import HotelIcon from "@material-ui/icons/Hotel";
// import RepeatIcon from "@material-ui/icons/Repeat";
// import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import config from "../../config";
import { QuestionCard, removeVideoQuesion } from "./lib/QuestionCard";

const useStyles = makeStyles((theme) => ({
  selectSeek: {
    cursor: "pointer",
  },
  seekArea: {
    position: 'relative',
    top: '13px'
  },
  selectRemove: {cursor: "pointer", color: 'red'},
}));

export default function VideoTimeLine({ questionSeek, timeSelected }) {
  React.useEffect(() => {
    console.log("questionSeek", questionSeek);
    setItems(questionSeek);
  }, [questionSeek]);
  const [items, setItems] = React.useState([]);
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {items.length &&
        items.map((item, x) => {
          return (
            <TimelineItem key={x}>
              <TimelineOppositeContent>
                <Typography
                  className={classes.seekArea}
                  variant="body2"
                  color="textSecondary"
                >
                  <span
                    onClick={() => {
                      timeSelected(item.seek);
                    }}
                    className={classes.selectSeek}
                  >
                    {item.seek} Seconds
                  </span>
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <RemoveCircleIcon
                    onClick={() => {
                      removeVideoQuesion(item).then(() => {
                        let filtered = items.filter(function (it) {
                          return it.id !== item.id;
                        });
                        setItems(filtered);
                      });
                    }}
                    className={classes.selectRemove}
                  />

                  {/* <QuestionAnswerIcon /> */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <QuestionCard item={item} key={x} />
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
}
