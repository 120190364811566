import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/index.scss";

import SignIn from "./components/auth/SignIn";
import Layout from "./components/Layout";
import Media from "./components/Media";
import SessionClose from "./components/SessionClose";
import Settings from "./components/settings/Settings";
import VideosQuestion from "./components/VideosQuestion";
import Test from "./components/test";
import Uploader from "./components/Uploader";
import LevelSorting from "./components/sorting/LevelSorting";
import TopicSorting from "./components/sorting/TopicSorting";
import Syncing from "./components/Syncing";
import SyncingAll from "./components/syncing/index";
import LogsList from "./components/LogsList";
import Question from "./components/Question";
import ListUser from "./components/users/ListUsers";
import ListStudents from "./components/students/ListStudents";
import ManageAssessment from "./components/assessment/Manage";
import ListSchools from "./components/schools/ListSchools";
import Metadata from "./components/Metadata";
import ProtectedRoute from "./ProtectedRoute";
import Attendance from "./components/attendance/Attendance";
import AssessmentHealth from "./components/health/AssessmentHealth";
import QuestionsCount from "./components/questionsCount/QuestionsCount";
import AdminRoute from "./AdminRoute";

const Root = () => {
  const [attendance, setAttendance] = React.useState(null);
  return (
    <Router>
      <Layout attendance={attendance}>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route
            path="/media"
            render={(props) => <Media {...props} attendance={setAttendance} />}
          />
          {/* <ProtectedRoute path="/test" component={Test} /> */}
          <ProtectedRoute path="/session-close" component={SessionClose} />
          <AdminRoute path="/videos-question" component={VideosQuestion} />
          <AdminRoute path="/questions-health" component={AssessmentHealth} />
          <AdminRoute path="/questions-count" component={QuestionsCount} />
          <ProtectedRoute path="/attendance" component={Attendance} />
          {/* <Route path="/test2" component={Test2} /> */}
          <AdminRoute path="/level-sorting" component={LevelSorting} />
          <AdminRoute path="/topic-sorting" component={TopicSorting} />
          <ProtectedRoute path="/uploader" component={Uploader} />
          <ProtectedRoute path="/syncing" component={Syncing} />
          <ProtectedRoute path="/syncing-all" component={SyncingAll} />
          <ProtectedRoute path="/video-log-list" component={LogsList} />
          <AdminRoute path="/question" component={Question} />
          <AdminRoute path="/users" component={ListUser} />
          <ProtectedRoute path="/students" component={ListStudents} />
          <ProtectedRoute path="/student-attendance" component={Attendance} />
          <ProtectedRoute
            path="/manage-assessment"
            component={ManageAssessment}
          />
          <AdminRoute path="/schools" component={ListSchools} />
          <AdminRoute path="/metadata" component={Metadata} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/test" component={Test} />

          {/* <Route path="/media" component={Media} /> */}
        </Switch>
      </Layout>
    </Router>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
