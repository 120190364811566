import React, { Component } from 'react';

import AuthService from '../auth/AuthService';
import AddSchool from './AddSchool';
import EditSchool from './EditSchool';

import Message from '../common/Message';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class ListSchools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schools: [],
      loadMessage: '',
      type: 'success',
    };

    this.msgInterval = null;
    this.Auth = new AuthService();
  }

  componentDidMount() {
    if (!this.Auth.loggedIn()) this.props.history.replace('/');
    this.setState({ loadMessage: 'Loading Schools', type: 'warning' });
    this.fetchData('Schools Loaded.');
  }

  // deleteSchool(id) {
  //   console.log("delete clicked");
  //   this.Auth.post("crud", {
  //     action: "del",
  //     table: "schools",
  //     id_key: "id",
  //     id_value: id,
  //   })
  //     .then((res) => {
  //       if (res.success === false) {
  //         this.setState({ isloading: false, errors: { form: res.message } });
  //       } else window.location.reload();
  //     })
  //     .catch((err) => {
  //       this.setState({ isloading: false, errors: { form: err } });
  //     });
  // }

  updateSchool(id) {
    console.log('update clicked');
  }

  fetchData(msg) {
    //   this.Auth.fetch("/api/user/list")
    //     .then(res => {
    //       this.setState({
    //         schools: res.schools,
    //         loadMessage: "Schools Loaded",
    //         type: "success",
    //       });
    //     })
    //     .catch(err => {
    //       this.setState({
    //         loadMessage: "some error occurred please try again",
    //         type: "error",
    //       });
    //       console.log(err);
    //     });

    this.Auth.post('crud', {
      action: 'select',
      table: 'schools',
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            schools: res.data,
            loadMessage: 'Schools Loaded',
            type: 'success',
          });
        }

        console.log('res', res);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    // const { classes } = this.props;
    return (
      <div style={{ padding: '20px' }}>
        <AddSchool propAddLink={this.propAddLink} plus={true} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>School ID</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Province</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Lat, long</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  Offline
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.schools.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.province}</TableCell>
                  <TableCell>
                    {row.latitude}, {row.longitude}
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell align="right">
                    {row.status === '1' ? 'Active' : 'Inactive'}
                  </TableCell>
                  <TableCell align="right">
                    {row.offline === '1' ? 'Offline' : 'Online'}
                  </TableCell>
                  <TableCell align="right">
                    {/* <Tooltip title="delete">
                      <DeleteIcon
                        style={{ cursor: "pointer", float: "right" }}
                        onClick={(event) => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this school?"
                            )
                          )
                            this.deleteSchool(row.id);
                        }}
                      />
                    </Tooltip> */}
                    <EditSchool
                      propAddLink={this.propAddLink}
                      plus={true}
                      id={row.id}
                      name={row.name}
                      address={row.address}
                      status={row.status}
                      province={row.province}
                      latitude={row.latitude}
                      longitude={row.longitude}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Message msg={this.state.loadMessage} type={this.state.type} />
      </div>
    );
  }
}

export default ListSchools;
